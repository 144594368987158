export default class ResponsiveManager {
    constructor() {
        this.updateDisplay();

        window.addEventListener('resize', () => {
            this.updateDisplay();
        });
    }

    updateDisplay() {
        if (window.innerWidth <= 1024) {
            document.querySelector('main').style.display = 'none';
            document.querySelector('#cursor').style.display = 'none';
            document.querySelector('.responsive').style.display = 'flex';
        } else if (window.innerWidth >= 1024) {
            document.querySelector('main').style.display = 'flex';
            document.querySelector('#cursor').style.display = 'flex';
            document.querySelector('.responsive').style.display = 'none';
        }
    }
}