import Place from '../classes/place';
import { sceneManager } from '../classes/sceneManager';
import Json from '../helpers/json';
import uiManager from '../utils/uiManager';
import ExperienceManager, {
    FIRST_SCENE,
    INTRO_SCENE,
    SECOND_SCENE,
    THIRD_SCENE,
    VILLAGE_SCENE
} from '../utils/experienceManager';
import * as THREE from 'three';
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {OutlinePass} from 'three/examples/jsm/postprocessing/OutlinePass';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {GammaCorrectionShader} from 'three/examples/jsm/shaders/GammaCorrectionShader';

export default class Village extends Place {
    constructor() {
        super(VILLAGE_SCENE,
            ['plan_4.1.fbx'],
            ['hubert_worried', 'hubert_stressed', 'hubert_embarrassed', 'street_sign_1', 'street_sign_2', 'street_sign_3']); // 4.3
        this.cameraConfig = {x: 492, y: 374, z: -454};
        this.controlConfig = {
            ...this.controlConfig,
            target: new THREE.Vector3(20, 5, 20),
            maxDistance: 800,
            minDistance: 20,
            enableRotate: true
        };
        this.setOffset(500);
        sceneManager.fogModifier(0.1, 0)
        this.svgLoader = new SVGLoader();
        this.hoverState = {
            hoverDispatched: false,
            notHoverDispatched: true
        };
        this.animateCamera = false;
        this.canHover = false;
    }

    addToSceneManager() {
        super.addToSceneManager();
        this.generateNeighborhoodModel();
    }

    setLightConfiguration() {
        super.setLightConfiguration();

        if (!this.hemiLight) {
            this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xf1975d, .5);
            this.hemiLight.name = 'hemiLight';
            this.hemiLight.position.set(50, 100, 50);
            this.group.add(this.hemiLight);
            this.spotLight = new THREE.SpotLight(0xc4844a, 1.76);
            this.spotLight.position.set(-583, 840, -300);
            this.spotLight.castShadow = true;
            this.spotLight.shadow.mapSize.width = 4096;
            this.spotLight.shadow.mapSize.height = 4096;
            this.spotLight.shadow.camera.near = 4;
            this.spotLight.shadow.camera.far = 2000;
            this.spotLight.shadow.camera.fov = 180;
            this.group.add(this.spotLight);
        }
    }

    removeSceneFromManager() {
        super.removeSceneFromManager();
        this.group.remove(this.neighborhoodModel);
        window.removeEventListener('mousemove', this.bindedHover);
        window.removeEventListener('click', this.bindedClick);
    }

    addModelToPlace(model) {
        const textures = {};
        model.traverse(function (child) {
            if (child.isMesh) {
                const textureName = child.material.name.split('_')[0];
                if (textureName !== '') {
                    if (!textures.hasOwnProperty((textureName))) {
                        textures[textureName] = child.material.map;
                    }
                }
            }
        });
        model.traverse(function (child) {
            if (child.isMesh) {
                const textureName = child.material.name.split('_')[0];
                if (textureName === '') {
                    child.material.map = textures[child.name.split('_')[0]];
                }
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });
        super.addModelToPlace(model);
    }


    setLastScene(lastScene) {
        super.setLastScene(lastScene);

        switch (this.lastScene) {
            case INTRO_SCENE:
                this.jsonIndex = 'intro';
                this.nextScene = FIRST_SCENE;
                break;
            case FIRST_SCENE:
                this.jsonIndex = 'first-scene';
                this.nextScene = SECOND_SCENE;
                break;
            case SECOND_SCENE:
                this.jsonIndex = 'second-scene';
                this.nextScene = THIRD_SCENE;
                break;
        }
    }

    displayTextUI() {
        super.displayTextUI();
        if (this.jsonObject) {
            uiManager.displaySceneExplanation(this.jsonObject[this.jsonIndex], () => {
                ExperienceManager.launchScene(this.nextScene);
            }, this.nextScene);
        } else {
            Json.getData('../data/town.json', (data) => {
                this.jsonObject = data;
                uiManager.displaySceneExplanation(data[this.jsonIndex], () => {
                    ExperienceManager.launchScene(this.nextScene);
                }, this.nextScene);
            });
        }
    }

    setOutline() {
        if (!this.composer) {
            this.composer = new EffectComposer(sceneManager.renderer);
            let renderPass = new RenderPass(sceneManager.scene, sceneManager.camera);
            renderPass.setSize(window.innerWidth, window.innerHeight);
            this.composer.addPass(renderPass);

            this.outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), sceneManager.scene, sceneManager.camera);
            this.outlinePass.depthMaterial.skinning = false;
            this.outlinePass.prepareMaskMaterial.skinning = false;
            this.outlinePass.visibleEdgeColor.set(0xffffff);
            this.outlinePass.hiddenEdgeColor.set(0x000000);
            this.outlinePass.edgeGlow = 0;
            this.outlinePass.edgeThickness = 1; // Default is 1.
            this.outlinePass.edgeStrength = 10; // Default is 3.
            this.composer.addPass(this.outlinePass);

            const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
            this.composer.addPass(gammaCorrectionPass);
        }
    }

    initSvgGeometry(geometry, fill, x, y, z) {
        this.neighborhoodModel = new THREE.Mesh(geometry, fill);
        this.neighborhoodModel.rotation.set(-Math.PI / 2, Math.PI, Math.PI);
        this.neighborhoodModel.position.set(x, y, z);

        this.group.add(this.neighborhoodModel);

        this.setOutline();

        window.addEventListener('can_hover', () => {
            this.outlinePass.selectedObjects = [this.neighborhoodModel];
            this.canHover = true;
        }, {once:true});
        sceneManager.elementsToIntersect = [this.neighborhoodModel];
        this.bindedHover = this.onHover.bind(this);
        this.bindedClick = this.onClick.bind(this);
        window.addEventListener('mousemove', this.bindedHover);
        window.addEventListener('click', this.bindedClick);
    }

    generateNeighborhoodModel() {
        const fill = new THREE.MeshPhongMaterial({
            color: 0x30A8FF,
            opacity: 0,
            transparent: true,
            depthWrite: false,
            side: THREE.DoubleSide
        });

        this.hoverState.hoverDispatched = false;
        this.hoverState.notHoverDispatched = true;

        switch (this.nextScene) {
            case FIRST_SCENE:
                this.getModelFromSvgPath('svg/neighborhood1.svg', (geometry) => {
                    this.initSvgGeometry(geometry, fill, -250, 11, -225);
                });
               break;
            case SECOND_SCENE:
                this.getModelFromSvgPath('svg/neighborhood2.svg', (geometry) => {
                    this.initSvgGeometry(geometry, fill, -120, 11, 100);
                });
                break;
            case THIRD_SCENE:
                this.getModelFromSvgPath('svg/neighborhood3.svg', (geometry) => {
                    this.initSvgGeometry(geometry, fill, 100, 11, -140);
                });
                break;
        }
    }

    onHover() {
        if (!this.canHover) {
            return;
        }

        if (sceneManager.mouseIntersect) {
            if (!this.hoverState.hoverDispatched) {
                this.hoverState.hoverDispatched = true;
                this.hoverState.notHoverDispatched = false;
                window.dispatchEvent(new Event('town_hover'));
                sceneManager.mouseIntersect.object.material.opacity = 0.3;
            }
        } else {
            if (!this.hoverState.notHoverDispatched) {
                this.hoverState.hoverDispatched = false;
                this.hoverState.notHoverDispatched = true;
                window.dispatchEvent(new Event('town_hover_none'));
                sceneManager.elementsToIntersect[0].material.opacity = 0;
            }
        }
    }

    onClick() {
        if (this.canHover && sceneManager.mouseIntersect) {
            this.canHover = false;
            sceneManager.elementsToIntersect[0].material.opacity = 0;
            this.cameraMovementOnClick();
            this.outlinePass.selectedObjects = [];
        }
    }

    getCanvasRelativePosition(event) {
        const rect = sceneManager.canvas.getBoundingClientRect();
        return {
            x: (event.clientX - rect.left) * sceneManager.canvas.width  / rect.width,
            y: (event.clientY - rect.top ) * sceneManager.canvas.height / rect.height,
        };
    }

    getModelFromSvgPath(path, onload) {
        this.svgLoader.load(
            path,
            function (data) {
                const path = data.paths[0];
                const shape = path.toShapes(true)[0];
                const geometry = new THREE.ShapeGeometry(shape);
                onload(geometry);
            }
        );
    }

    cameraMovementOnClick() {
        switch (this.nextScene) {
            case FIRST_SCENE:
                this.cameraSpline = new THREE.CatmullRomCurve3([
                    sceneManager.camera.position.clone(),
                    new THREE.Vector3(160, 40, -190),
                    new THREE.Vector3(0, 30, -190),
                    new THREE.Vector3(-180, 20, -190),
                    new THREE.Vector3(-180, 20, -100),
                ]);
            break;
            case SECOND_SCENE:
                this.cameraSpline = new THREE.CatmullRomCurve3([
                    sceneManager.camera.position.clone(),
                    new THREE.Vector3(160, 40, -195),
                    new THREE.Vector3(114, 29, -125),
                    new THREE.Vector3(110, 29, 14),
                    new THREE.Vector3(105, 29, 80),
                    new THREE.Vector3(74, 29, 95),
                    new THREE.Vector3(63, 29, 92),
                    new THREE.Vector3(56, 29, 92),
                    new THREE.Vector3(14, 29, 116)
                ]);
                break;
            case THIRD_SCENE:
                this.cameraSpline = new THREE.CatmullRomCurve3([
                    sceneManager.camera.position.clone(),
                    new THREE.Vector3(160, 40, -195),
                    new THREE.Vector3(114, 29, -125),
                    new THREE.Vector3(110, 29, 14),
                    new THREE.Vector3(120, 29, 84),
                    new THREE.Vector3(140, 29, 97),
                    new THREE.Vector3(208, 29, 97)
                ]);
                break;
        }
        this.cameraSplineGeometry = new THREE.TubeGeometry(this.cameraSpline);
        // debug
        // const material = new THREE.MeshLambertMaterial({ color: 0xff00ff });
        // const wireframeMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, opacity: 0.3, wireframe: true, transparent: true });
        // const mesh = new THREE.Mesh(this.cameraSplineGeometry, material);
        // mesh.scale.set(1, 1, 1);
        // const wireframe = new THREE.Mesh(this.cameraSplineGeometry, wireframeMaterial);
        // mesh.add(wireframe);
        // this.group.add(mesh);

        this.animateCamera = true;
        this.direction = new THREE.Vector3();
        this.normal = new THREE.Vector3(0, 1, 0);
        this.position = sceneManager.camera.position.clone();
        this.lookAt = new THREE.Vector3();
        this.elapsedTime = 0;
    }

    animate(deltaTime) {
        if (this.animateCamera) {
            const looptime = 9 * 1000;
            this.elapsedTime += deltaTime;
            const t = ((this.elapsedTime * 1000) % looptime) / looptime;

            this.cameraSplineGeometry.parameters.path.getPointAt(t, this.position);

            // interpolation
            const segments = this.cameraSplineGeometry.tangents.length;
            const pickt = t * segments;
            const pick = Math.floor(pickt);
            const pickNext = (pick + 1) % segments;

            if (pick > 0 && pickNext === 0) {
                this.animateCamera = false;
                window.dispatchEvent(new Event('town_hover_click'));
                return;
            }

            this.cameraSplineGeometry.parameters.path.getTangentAt(t, this.direction);

            sceneManager.camera.position.copy(this.position);

            // using arclength for stablization in look ahead
            this.cameraSplineGeometry.parameters.path.getPointAt((t + 50 / this.cameraSplineGeometry.parameters.path.getLength()) % 1, this.lookAt);

            // camera orientation 2 - up orientation via normal
            this.lookAt.copy(this.position).add(this.direction);
            sceneManager.camera.matrix.lookAt(sceneManager.camera.position, this.lookAt, this.normal);
            sceneManager.camera.quaternion.setFromRotationMatrix(sceneManager.camera.matrix);
        }
        if (this.composer) {
            this.composer.render();
        }
    }
} 
