import Place from '../classes/place';
import * as THREE from 'three';
import Json from '../helpers/json';
import uiManager from '../utils/uiManager';
import ExperienceManager, { INTRO_SCENE } from '../utils/experienceManager';
import { sceneManager } from '../classes/sceneManager';

export default class Intro extends Place {
    constructor() {
        super(INTRO_SCENE, ['hubert_3.4.gltf']);
        this.cameraConfig = { x: 25, y: 10, z: 25 };
        this.controlConfig = {
            ...this.controlConfig,
            maxDistance: 35,
            minDistance: 5,
            enableRotate: true
        };
        this.animationRunning = false;
        this.decors = new THREE.Group();
        this.rocksStripes = [];
        sceneManager.fogModifier(140, 170)
        this.hubert = null;
        this.animations = [];
        this.mixer = [];
    }

    displayTextUI() {
        super.displayTextUI();

        Json.getData('../data/intro.json', (data) => {
            uiManager.displayExplanation(data, () => {
                ExperienceManager.backToTown();
            });
        });
    }

    addModelToPlace(model) {
        model.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        let stripe = model.children.find((child) => child.name === 'decors');
        this.hubert = model.children.find((child) => child.name === 'groupeFacteur');

        this.hubert.scale.set(0.05, 0.05, 0.05);
        this.hubert.position.set(0.0, 0.0, 0.0);

        this.duplicateRockStrip(stripe);
        this.group.add(this.hubert);
        this.animator();
        this.convertToPhongMaterial(this.decors);
    }

    convertToBasicMeshMaterial(model) {
        model.traverse(child => {
            if (child.isMesh) {
                const prevMaterial = child.material
                const newMaterial = new THREE.MeshBasicMaterial()
                newMaterial.copy(prevMaterial)
                newMaterial.map = prevMaterial.emissiveMap // Dans le cas ou il n'y a rien dans la propriété map
                child.material = newMaterial
            }
        })
    }

    convertToPhongMaterial(model) {
        model.traverse(child => {
            if (child.isMesh) {
                const prevMaterial = child.material;
                const newMaterial = new THREE.MeshPhongMaterial();
                newMaterial.color = prevMaterial.color;
                child.material = newMaterial;
            }
        })
    }

    setLightConfiguration() {
        super.setLightConfiguration();

        if (!this.hemiLight) {
            this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xf1975d, .2);
            this.hemiLight.name = 'hemiLight';
            this.hemiLight.position.set(50, 100, 50);
            this.group.add(this.hemiLight);
            this.spotLight = new THREE.SpotLight(0xc4844a, 0.3);
            this.spotLight.position.set(38, 42, 15);
            this.spotLight.name = 'spotLightDebug';
            this.spotLight.castShadow = true;
            this.spotLight.shadow.mapSize.width = 4096;
            this.spotLight.shadow.mapSize.height = 4096;
            this.spotLight.shadow.camera.near = 4;
            this.spotLight.shadow.camera.far = 2000;
            this.spotLight.shadow.camera.fov = 180;
            this.group.add(this.spotLight);
        }
    }

    animator() {
        let mixer = new THREE.AnimationMixer(this.hubert)
     
        let theySeeMeRolling = THREE.AnimationClip.findByName(this.animations, 'animation_0');
        const action = mixer.clipAction(theySeeMeRolling)
        action.play()
        this.mixer.push(mixer)
    }

    duplicateRockStrip(stripe) {
        for (let index = 0; index < 3; index++) {
            let copy = stripe.clone();
            copy.name = 'BandeCailloux';

            copy.position.z = index * - 2000;
            this.decors.add(copy);
            this.rocksStripes.push(copy);
        }
        this.decors.name = 'Decors';
        this.decors.scale.set(0.05, 0.05, 0.05);
        this.group.add(this.decors);
    }

    animate(deltaTime) {
        super.animate(deltaTime);
        if (this.mixer.length) {
            this.mixer.forEach(mixer => {
                mixer.update(deltaTime)
            });
        
        }
        this.rocksStripes?.forEach(stripe => {
            if (stripe) {
                stripe.position.z = stripe.position.z - 100 * deltaTime;
                if (stripe.position.z <= -4000) {
                    stripe.position.z = 1000;
                }
            }
        });
    }
}
