import {FIRST_SCENE, SECOND_SCENE, THIRD_SCENE} from './experienceManager';
import {sceneManager} from '../classes/sceneManager';
import {cursorSingleton} from '../uiComponents/cursor';
import SoundManager, {POP_MESSAGE} from './soundManager';

export default class uiManager {
    static startExperience() {
        uiManager.setSound();
        const startButton = document.querySelector('.TitleScreen-stamp');
        cursorSingleton.registerCursor([startButton], 'entrer');
        startButton.addEventListener('click', () => {
            SoundManager.startBackgroundSound();
            document.querySelector('.TitleScreen').classList.add('hidden');
            cursorSingleton.unregisterCursor();
        }, {once:true});
    }
    // for intro and conclusion
    static displayExplanation(data, lastButtonAction, replaceAtFirst = false) {
        if (replaceAtFirst) {
            uiManager.replaceUserName(data[0]);
        }
        const titleParagraphElement = document.getElementsByClassName('title-paragraph')[0];
        const textElement = document.getElementsByClassName('text')[0];
        const titleNameElement = document.getElementsByClassName('title-name')[0];
        const orangeTextElement = document.getElementsByClassName('orange-text')[0];
        const nextButtonElement = document.getElementsByClassName('next-button')[0];
        const explanationElement = document.getElementById('explanation');
        const inputElement = document.getElementsByClassName('input-name')[0];

        titleNameElement.style.opacity = '0';
        titleParagraphElement.style.opacity = '0';
        textElement.style.opacity = '0';
        nextButtonElement.style.opacity = '0';

        explanationElement.style.display = 'block';

        let counter = 0;

        const updateData = (title, titleParagraph, paragraph, button, displayInputName) => {
            uiManager.replaceUserName(data[counter]);
            counter++;
            titleParagraphElement.style.opacity = '0';
            textElement.style.opacity = '0';

            inputElement.style.display = displayInputName ? 'block' : 'none';
            inputElement.style.opacity = '0';

            nextButtonElement.style.display = displayInputName ? 'none' : 'flex';

            if (displayInputName) {
                inputElement.addEventListener('input', () => {
                    nextButtonElement.style.display = 'flex';
                }, {once: true});

                const onclick = () => {
                    sceneManager.name = document.querySelector('input').value
                        .replace(/&/g, "&amp;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#039;");
                    uiManager.replaceUserName(data[counter]);
                    updateData(data[counter]['title'], data[counter]['title-paragraph'], data[counter]['paragraph'], data[counter]['button'], data[counter]['display-input-name']);
                    document.querySelector('form').removeEventListener('submit', submitListener);
                    nextButtonElement.removeEventListener('click', onclick);
                };

                const submitListener = (event) => {
                    event.preventDefault();

                    if (document.querySelector('input').value !== '') {
                        sceneManager.name = document.querySelector('input').value
                            .replace(/&/g, "&amp;")
                            .replace(/</g, "&lt;")
                            .replace(/>/g, "&gt;")
                            .replace(/"/g, "&quot;")
                            .replace(/'/g, "&#039;");
                        uiManager.replaceUserName(data[counter]);
                        updateData(data[counter]['title'], data[counter]['title-paragraph'], data[counter]['paragraph'], data[counter]['button'], data[counter]['display-input-name']);
                        document.querySelector('form').removeEventListener('submit', submitListener);
                        nextButtonElement.removeEventListener('click', onclick);
                    }
                };

                nextButtonElement.addEventListener('click', onclick);

                document.querySelector('form').addEventListener('submit', submitListener);
            }

            setTimeout(() => {
                titleNameElement.innerHTML = title;
                titleParagraphElement.innerHTML = titleParagraph;
                textElement.innerHTML = paragraph;
                orangeTextElement.innerHTML = button;

                if (titleParagraph !== "") {
                    titleParagraphElement.style.display = 'block';
                } else {
                    titleParagraphElement.style.display = 'none';
                }

                if (counter >= data.length) {
                    nextButtonElement.addEventListener('click', () => {
                        explanationElement.style.display = 'none';
                        titleParagraphElement.style.opacity = '0';
                        textElement.style.opacity = '0';
                        inputElement.style.opacity = '0';
                        lastButtonAction();
                    }, {once:true});
                } else if (!displayInputName) {
                    nextButtonElement.addEventListener('click', () => {
                        updateData(data[counter]['title'], data[counter]['title-paragraph'], data[counter]['paragraph'], data[counter]['button'], data[counter]['display-input-name']);
                    }, {once:true});
                }

                setTimeout(() => {
                    titleNameElement.style.opacity = '1';
                    titleParagraphElement.style.opacity = '1';
                    textElement.style.opacity = '1';
                    nextButtonElement.style.opacity = '1';
                    inputElement.style.opacity = '1';
                }, 100);

            }, 300);
        }

        updateData(data[counter]['title'], data[counter]['title-paragraph'], data[counter]['paragraph'], data[counter]['button'], data[counter]['display-input-name']);
    }

    static displayDialogWithTimeout(dialog, index = 0) {
        const timeout = 700;

        if (dialog.length === index) {
            setTimeout(() => document.getElementsByClassName('next-button')[2].style.opacity = '1', timeout);
            return;
        }

        setTimeout(() => {
            SoundManager.playSound(POP_MESSAGE);
            dialog[index].style.display = 'flex';
            dialog[index].classList.add('animate');
            index++;
            uiManager.displayDialogWithTimeout(dialog, index);
        }, timeout);
    }

    static displayDialog(data, lastButtonAction, scene, isFirst = false) {
        uiManager.replaceUserName(data);
        uiManager.updateBreadcrumb(scene, 3);

        const dialogContainerElement = document.getElementById('dialog-container');
        const dialogElement = document.getElementById('dialog');
        const titleMainElement = document.getElementsByClassName('title-main')[1];
        const orangeTextElement = document.getElementsByClassName('orange-text')[2];
        const nextButtonElement = document.getElementsByClassName('next-button')[2];

        titleMainElement.style.opacity = '0';
        dialogContainerElement.style.opacity = '0';
        nextButtonElement.style.opacity = '0';
        dialogElement.style.display = 'block';
        let counter = 0;

        const updateData = (title, dialog, button) => {
            counter++;
            dialogContainerElement.style.opacity = '0';
            setTimeout(() => {
                dialogContainerElement.innerHTML = '';
                dialog.forEach((each) => {
                    if (each['position'] === 'left') {
                        dialogContainerElement.innerHTML += '<div class="left" style="display:none"><img src="svg/' + each['img']['url'] + '.svg" alt="' + each['img']['alt'] + '"><div><p class="title-paragraph-dialog">' + each['title-paragraph'] + '</p><p class="text-dialog">' + each['paragraph'] + '</p></div></div>';
                    } else {
                        dialogContainerElement.innerHTML += '<div class="right" style="display:none"><div><p class="title-paragraph-dialog">' + each['title-paragraph'] + '</p><p class="text-dialog">' + each['paragraph'] + '</p></div><img src="svg/' + each['img']['url'] + '.svg" alt="' + each['img']['alt'] + '"></div>'
                    }
                });
                if (isFirst) {
                    isFirst = false;
                    setTimeout(() => {
                        uiManager.displayDialogWithTimeout(dialogContainerElement.children);
                    }, 2000);
                } else {
                    uiManager.displayDialogWithTimeout(dialogContainerElement.children);
                }
                titleMainElement.innerHTML = title;
                orangeTextElement.innerHTML = button;
                if (counter === data.length) {
                    nextButtonElement.addEventListener('click', () => {
                        dialogElement.style.display = 'none';
                        titleMainElement.style.opacity = '0';
                        dialogContainerElement.style.opacity = '0';
                        nextButtonElement.style.opacity = '0';
                        lastButtonAction();
                    }, {once:true});
                } else {
                    nextButtonElement.addEventListener('click', () => {
                        updateData(data[counter]['title'], data[counter]['dialog'], data[counter]['button']);
                    }, {once:true});
                }
                setTimeout(() => {
                    titleMainElement.style.opacity = '1';
                    dialogContainerElement.style.opacity = '1';
                }, 100);
            }, 300);
        }
        updateData(data[0]['title'], data[0]['dialog'], data[0]['button']);
    }

    static displaySceneExplanation(data, lastButtonAction, scene) {
        uiManager.replaceUserName(data);
        uiManager.updateBreadcrumb(scene, 0);
        const sceneExplanationElement = document.getElementById('scene-explanation');
        const textElement = document.getElementsByClassName('text')[1];
        const personPhotoElement = document.getElementsByClassName('person-photo')[0];
        const titleMainElement = document.getElementsByClassName('title-main')[0];
        const titleParagraphElement = document.getElementsByClassName('title-paragraph')[1];
        const orangeTextElement = document.getElementsByClassName('orange-text')[1];
        const nextButtonElement = document.getElementsByClassName('next-button')[1];

        titleMainElement.style.opacity = '0';
        personPhotoElement.style.opacity = '0';
        titleParagraphElement.style.opacity = '0';
        textElement.style.opacity = '0';
        nextButtonElement.style.opacity = '0';

        sceneExplanationElement.style.display = 'block';
        let counter = 0;

        const updateData = (title, imgUrl, imgAlt, titleParagraph, paragraph, button, displayButton, updateCounter = true) => {
            counter += updateCounter ? 1 : 0;

            personPhotoElement.style.opacity = personPhotoElement.alt === imgAlt ? '1' : '0';
            titleParagraphElement.style.opacity = titleParagraphElement.innerHTML === titleParagraph ? '1' : '0';
            textElement.style.opacity = '0';
            nextButtonElement.style.opacity = displayButton ? '1' : '0';

            setTimeout(() => {
                titleMainElement.innerHTML = title;
                personPhotoElement.src = `svg/${imgUrl}.svg`;
                personPhotoElement.alt = imgAlt;
                titleParagraphElement.innerHTML = titleParagraph;
                textElement.innerHTML = paragraph;
                orangeTextElement.innerHTML = button;

                if (counter === data['scenes'].length) {
                    window.dispatchEvent(new Event('can_hover'));
                    if (displayButton) {
                        nextButtonElement.addEventListener('click', () => {
                            sceneExplanationElement.style.display = 'none';
                            titleMainElement.style.opacity = '0';
                            personPhotoElement.style.opacity = '0';
                            titleParagraphElement.style.opacity = '0';
                            textElement.style.opacity = '0';
                            nextButtonElement.style.opacity = '0';
                            lastButtonAction();
                        }, {once:true});
                    }
                } else if (displayButton) {
                    nextButtonElement.addEventListener('click', () => {
                        updateData(data['scenes'][counter]['title'], data['scenes'][counter]['img']['url'], data['scenes'][counter]['img']['alt'], data['scenes'][counter]['title-paragraph'], data['scenes'][counter]['paragraph'], data['scenes'][counter]['button'], data['scenes'][counter]['display-button']);
                    }, {once:true});
                }

                setTimeout(() => {
                    titleMainElement.style.opacity = '1';
                    personPhotoElement.style.opacity = '1';
                    titleParagraphElement.style.opacity = '1';
                    textElement.style.opacity = '1';
                    nextButtonElement.style.opacity = displayButton ? '1' : '0';
                }, 100);

            }, 300);

        }

        updateData(data['scenes'][0]['title'], data['scenes'][0]['img']['url'], data['scenes'][0]['img']['alt'], data['scenes'][0]['title-paragraph'], data['scenes'][0]['paragraph'], data['scenes'][0]['button'], data['scenes'][0]['display-button']);

        if (data['on-hover-scene']) {
            const onHover = () => {
                if (counter === data['scenes'].length) {
                    updateData(data['on-hover-scene']['title'], data['on-hover-scene']['img']['url'], data['on-hover-scene']['img']['alt'], data['on-hover-scene']['title-paragraph'], data['on-hover-scene']['paragraph'], data['on-hover-scene']['button'], data['on-hover-scene']['display-button'], false);
                }
            };
            const onHoverClose = () => {
                if (counter >= data['scenes'].length) {
                    counter--;
                    updateData(data['scenes'][counter]['title'], data['scenes'][counter]['img']['url'], data['scenes'][counter]['img']['alt'], data['scenes'][counter]['title-paragraph'], data['scenes'][counter]['paragraph'], data['scenes'][counter]['button'], data['scenes'][counter]['display-button']);
                }
            };
            const onHoverClick = () => {
                if (counter >= data['scenes'].length) {
                    sceneExplanationElement.style.display = 'none';
                    titleMainElement.style.opacity = '0';
                    personPhotoElement.style.opacity = '0';
                    titleParagraphElement.style.opacity = '0';
                    textElement.style.opacity = '0';
                    nextButtonElement.style.opacity = '0';
                    window.removeEventListener('town_hover', onHover);
                    window.removeEventListener('town_hover_none', onHoverClose);
                    window.removeEventListener('town_hover_click', onHoverClick);
                    lastButtonAction();
                }
            };
            window.addEventListener('town_hover', onHover);
            window.addEventListener('town_hover_none', onHoverClose);
            window.addEventListener('town_hover_click', onHoverClick);
        }
    }

    static displaySceneConclusion(data, buttonAction) {
        uiManager.replaceUserName(data);
        const sceneConclusionElement = document.getElementById('scene-conclusion');

        sceneConclusionElement.style.display = 'block';

        document.getElementsByClassName('title-main')[2].innerHTML = data['title'];
        document.getElementsByClassName('text')[2].innerHTML = data['paragraph'];
        document.getElementsByClassName('orange-text')[3].innerHTML = data['button'];
        const video = document.querySelector('video');
        document.querySelector('source').src = '../videos/' + data['gif'] + '.mp4';
        video.load();

        document.getElementsByClassName('next-button')[3].addEventListener('click', () => {
            document.getElementsByClassName('title-main')[2].style.opacity = '0';
            document.getElementsByClassName('text')[2].style.opacity = '0';
            document.getElementsByClassName('next-button')[3].style.opacity = '0';
            sceneConclusionElement.style.display = 'none';
            buttonAction();
        }, {once:true});

        document.getElementsByClassName('title-main')[2].style.opacity = '1';
        setTimeout(() => {
            document.getElementsByClassName('text')[2].style.opacity = '1';
        }, 2000);
        video.onended = () => {
            document.getElementsByClassName('next-button')[3].style.opacity = '1';
        };
        video.play();
    }

    static displayInteraction(data, lastButtonAction) {
        uiManager.replaceUserName(data);
        const sceneInteractionElement = document.getElementById('scene-interaction');
        const textContentElement = document.getElementsByClassName('text-content')[3];
        const titleInteractionElement = document.getElementsByClassName('title-interaction')[0];
        const subheadElement = document.getElementsByClassName('text')[3];
        const titleParagraphElement = document.getElementsByClassName('title-paragraph')[2];
        const textElement = document.getElementsByClassName('text')[4];
        const orangeTextElement = document.getElementsByClassName('orange-text')[4];
        const linkImgElement = document.getElementsByClassName('link-img')[1];
        const buttonInstructionsElement = document.getElementsByClassName('button-instructions')[1];
        const nextButtonElement = document.getElementsByClassName('next-button')[4];
        const onInteraction = () => {
            if (counter >= data.length) {
                sceneInteractionElement.style.display = 'none';
                titleInteractionElement.style.opacity = '0';
                subheadElement.style.opacity = '0';
                titleParagraphElement.style.opacity = '0';
                textElement.style.opacity = '0';
                nextButtonElement.style.opacity = '0';
                linkImgElement.style.opacity = '0';
                lastButtonAction();
            } else {
                updateData(data[counter]['title'], data[counter]['subhead'], data[counter]['display-explanation'], data[counter]['title-paragraph'], data[counter]['text'], data[counter]['button'], data[counter]['svg']['url'], data[counter]['svg']['alt'], data[counter]['instruction-svg']['url'], data[counter]['instruction-svg']['alt']);
            }
        };

        titleInteractionElement.style.opacity = '0';
        subheadElement.style.opacity = '0';
        titleParagraphElement.style.opacity = '0';
        textElement.style.opacity = '0';
        nextButtonElement.style.opacity = '0';
        linkImgElement.style.opacity = '0';

        sceneInteractionElement.style.display = 'block';
        textContentElement.style.display = 'none';
        let counter = 0;

        const updateData = (title, subhead, displayExplanation, titleParagraph, text, button, svgUrl, svgAlt, instructionSvg, instructionAlt) => {
            counter++;
            textElement.style.opacity = '0';

            textContentElement.style.display = displayExplanation ? 'block' : 'none';

            setTimeout(() => {
                titleInteractionElement.innerHTML = title;
                subheadElement.innerHTML = subhead;
                titleParagraphElement.innerHTML = titleParagraph;
                textElement.innerHTML = text;
                orangeTextElement.innerHTML = button;
                linkImgElement.src = svgUrl ? '../svg/' + svgUrl + '.svg' : '';
                linkImgElement.alt = svgUrl ? svgAlt : '';
                buttonInstructionsElement.src = '../svg/' + instructionSvg + '.svg';
                buttonInstructionsElement.alt = instructionSvg ? instructionAlt : '';

                if (displayExplanation) {
                    if (data[counter-1]['display-button']) {
                        if (counter === data.length) {
                            nextButtonElement.addEventListener('click', () => {
                                if (data[counter - 1]['send-can-over']) {
                                    window.dispatchEvent(new Event('can_hover'));
                                }
                                sceneInteractionElement.style.display = 'none';
                                titleInteractionElement.style.opacity = '0';
                                subheadElement.style.opacity = '0';
                                titleParagraphElement.style.opacity = '0';
                                textElement.style.opacity = '0';
                                nextButtonElement.style.opacity = '0';
                                linkImgElement.style.opacity = '0';
                                lastButtonAction();
                            }, {once:true});
                        } else {
                            nextButtonElement.addEventListener('click', () => {
                                if (data[counter - 1]['send-can-over']) {
                                    window.dispatchEvent(new Event('can_hover'));
                                }
                                updateData(data[counter]['title'], data[counter]['subhead'], data[counter]['display-explanation'], data[counter]['title-paragraph'], data[counter]['text'], data[counter]['button'], data[counter]['svg']['url'], data[counter]['svg']['alt'], data[counter]['instruction-svg']['url'], data[counter]['instruction-svg']['alt']);
                            }, {once:true});
                        }
                    } else {
                        window.addEventListener('interaction_continue', onInteraction, {once:true});
                    }
                } else {
                    window.addEventListener('interaction_continue', onInteraction, {once:true});
                }

                setTimeout(() => {
                    titleInteractionElement.style.opacity = '1';
                    subheadElement.style.opacity = '1';
                    titleParagraphElement.style.opacity = '1';
                    textElement.style.opacity = '1';
                    nextButtonElement.style.opacity = data[counter-1]['display-button'] ? '1' : '0';
                    linkImgElement.style.opacity = '1';
                }, 100);

            }, 300);

        }

        updateData(data[0]['title'], data[0]['subhead'], data[0]['display-explanation'], data[0]['title-paragraph'], data[0]['text'], data[0]['button'], data[0]['svg']['url'], data[0]['svg']['alt'], data[0]['instruction-svg']['url'], data[0]['instruction-svg']['alt']);
    }

    static updateBreadcrumb(scene, offset) {
        const numberElements = document.getElementsByClassName('number');

        if (scene === FIRST_SCENE) {
            numberElements[offset].classList.add('active');
            numberElements[offset+1].classList.remove('active');
            numberElements[offset+2].classList.remove('active');
        } else if (scene === SECOND_SCENE) {
            numberElements[offset].classList.remove('active');
            numberElements[offset+1].classList.add('active');
            numberElements[offset+2].classList.remove('active');
        } else if (scene === THIRD_SCENE) {
            numberElements[offset].classList.remove('active');
            numberElements[offset+1].classList.remove('active');
            numberElements[offset+2].classList.add('active');
        }
    }

    static replaceUserName(data) {
        if (Array.isArray(data)) {
            data.forEach((_data) => uiManager.replaceUserName(_data));
            return;
        }
        if (typeof data === 'object') {
            Object.entries(data).forEach(([key, _data]) => {
                if (Array.isArray(_data)) {
                    _data.forEach((__data) => uiManager.replaceUserName(__data));
                    return;
                }
                if (typeof _data === 'string') {
                    data[key] = _data.replace('[user-name]', sceneManager.name);
                }
            });
        }
    }

    static displayEndExperience() {
        const endButtonElement = document.getElementById('end-button');
        endButtonElement.style.display = 'block';

        endButtonElement.addEventListener('click', () => {
            SoundManager.pauseSound('../sounds/bande_organise.ogg');
            const endExperienceElement = document.getElementById('end-experience');
            endExperienceElement.style.display = 'block';

            document.getElementsByClassName('next-button')[5].addEventListener('click', () => {
                document.location.reload();
            }, {once:true});
        }, {once: true});
    }

    static setSound() {
        const mute = document.getElementById('mute');
        const unmute = document.getElementById('unmute');
        mute.addEventListener('click', () => {
            mute.classList.remove('active');
            unmute.classList.add('active');
            SoundManager.mute();
        });

        unmute.addEventListener('click', () => {
            mute.classList.add('active');
            unmute.classList.remove('active');
            SoundManager.unmute();
        });
    }
}