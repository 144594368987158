import {sceneManager} from '../classes/sceneManager';

export default class ExperienceManager {

    static init() {
        ExperienceManager.initExperience();
    }

    static initExperience() {
        sceneManager.initScene();
        ExperienceManager.launchIntroduction();
    }

    static launchIntroduction() {
        sceneManager.removeActivePlace(); // remove place from the anim loop
        sceneManager.setActivePlace(INTRO_SCENE); // add place to the anim loop
    }

    static launchScene(scene) {
        const lastScene = sceneManager.activePlace;
        sceneManager.removeActivePlace(); // remove place from the anim loop
        sceneManager.setActivePlace(scene, lastScene.name); // add place to the anim loop
    }

    static endScene() {
        sceneManager.removeActivePlace(); // remove place from the anim loop
    }

    static backToTown() {
        ExperienceManager.launchScene(VILLAGE_SCENE);
    }

    static endOfExperience() {
        // TODO sceneManager.unloadScene()
    }
}

const INTRO_SCENE = 'intro';
const VILLAGE_SCENE = 'village';
const FIRST_SCENE = 'scene_01';
const SECOND_SCENE = 'scene_02';
const THIRD_SCENE = 'scene_03';
const END_SCENE = 'end_scene';
const CONCLUSION = 'conclusion';
export { INTRO_SCENE, VILLAGE_SCENE, FIRST_SCENE, SECOND_SCENE, THIRD_SCENE, END_SCENE, CONCLUSION }