import * as THREE from 'three';
import Place from '../classes/place';
import uiManager from '../utils/uiManager';
import Json from '../helpers/json';
import ExperienceManager, { FIRST_SCENE } from '../utils/experienceManager';
import { sceneManager } from '../classes/sceneManager';
import SoundManager, {FAIL, WIN} from "../utils/soundManager";

export default class SceneOne extends Place {
    constructor() {
        super(FIRST_SCENE,
            ['scene01_4.2.fbx', 'moniqueAnimationPart1_1.0.fbx', 'moniqueAnimationPart2_1.0.fbx', 'khloeAnimation.fbx', 'hubertAnimationTalking.fbx'],
            ['monique_angry', 'hubert_embarrassed', 'khloe', 'hubert_happy', 'monique_reassured']);
        this.cameraConfig = { x: 65, y: 50, z: -55 };
        this.controlConfig = {
            ...this.controlConfig,
            target: new THREE.Vector3(0, 10, 0),
            maxDistance: 100,
            minDistance: 5,
            enableRotate: false,
            enableZoom: true
        };
        this.setOffset(500);
        this.garbagesArray = [];
        this.shotCount = 0;
        this.currentGarbage = null;
        this.garbages = null;

        this.greenTrashes = null;
        this.yellowTrashes = null;
        this.blackTrashes = null;

        this.currentInteraction = 1;
        this.line = null
        this.canClick = true;
        this.animateCamera = false;
        this.initialPoints = [
            new THREE.Vector3(-4, 8, -2),
            new THREE.Vector3(5, 13, -2),
            new THREE.Vector3(25, 5, -2),
            new THREE.Vector3(25, 0, -2),
        ];
      
        this.curvePoints = []
        this.floor = new THREE.Mesh(
            new THREE.PlaneGeometry(40, 50, 1, 1),
            new THREE.ShadowMaterial({
                opacity: 0.8,
                color: 0x161724,
            })
        );
        this.mixer = [];

        // Bug on dynamique trajectory 
        // --> quick fix : use positions array
        this.greenTrajectory1 = [
            new THREE.Vector3(-4, 8, -2),
            new THREE.Vector3(1, 13, 0),
            new THREE.Vector3(26, 5, 2),
            new THREE.Vector3(26, 2, 2),
        ];
        this.blackTrajectory1 = [
            new THREE.Vector3(-4, 8, -2),
            new THREE.Vector3(1, 13, -4),
            new THREE.Vector3(25, 5, -6),
            new THREE.Vector3(25, 2, -6),
        ];
        this.greenTrajectory2 = [
            new THREE.Vector3(-4, 8, 2.5),
            new THREE.Vector3(1, 13, 2.5),
            new THREE.Vector3(25, 5, 2.5),
            new THREE.Vector3(25, 2, 2.5),
        ];
        this.yellowTrajectory = [
            new THREE.Vector3(-4, 8, 2.5),
            new THREE.Vector3(1, 13, 5.5),
            new THREE.Vector3(25, 5, 9.5),
            new THREE.Vector3(25, 2, 9.5),
        ];
        this.blackTrajectory2 = [
            new THREE.Vector3(-4, 8, 2.5),
            new THREE.Vector3(1, 13, -0.5),
            new THREE.Vector3(26, 5, -4.5),
            new THREE.Vector3(26, 2, -4.5),
        ];

        this.initFloor()
    }

    addModelToPlace(model) {
        switch (model.name) {
            case 'scene01': {
                // this.sceneModel = model.children.find((child) => child.name === "scene01");
                this.sceneModel = model;

                super.addModelToPlace(this.sceneModel);
                this.sceneModel.traverse(function (child) {
                    if (child.isMesh) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                });

                this.blackTrashes = model.children.find((child) => child.name === "trash01");
                this.greenTrashes = model.children.find((child) => child.name === "trash02");
                this.yellowTrashes = model.children.find((child) => child.name === "trash03");
                this.displayTwoTrashes()


                this.garbagesArray.push(model.getObjectByName("bottle"));
                this.garbagesArray.push(model.getObjectByName("yogurt"));
                this.garbagesArray.push(model.getObjectByName("trashBag"));
                this.garbagesArray.push(model.getObjectByName("pot"));

                this.garbagesArray.forEach(garbage => {
                    garbage.position.set(0.0, -5.0, 0.0);
                    garbage.scale.set(0.15, 0.15, 0.15);
                    super.convertToPhongMaterial(garbage);
                    this.group.add(garbage);
                });

            } break;
            case 'moniqueAnimationPart1': {
                this.moniqueAnimationPart1 = model;
            } break;
            case 'moniqueAnimationPart2': {
                this.moniqueAnimationPart2 = model;
            } break;
            case 'hubertAnimationTalking': {
                this.hubertAnimationTalking = model;
            } break;
            case 'khloeAnimation': {
                this.khloeAnimation = model;
            } break;
        }
    }

    addToSceneManager() {
        this.addPnj();
        super.addToSceneManager();
    }

    setLightConfiguration() {
        super.setLightConfiguration();

        if (!this.hemiLight) {
            this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xf1975d, .3);
            this.hemiLight.name = 'hemiLight';
            this.hemiLight.position.set(50, 100, 50);
            this.group.add(this.hemiLight);
            this.spotLight = new THREE.SpotLight(0xc4844a, 0.4);
            this.spotLight.position.set(-132, 265, -100);

            // sceneManager.spotLightDebug = this.spotLight;
            this.spotLight.name = 'spotLightDebug';
            this.spotLight.castShadow = true;
            this.spotLight.shadow.mapSize.width = 4096;
            this.spotLight.shadow.mapSize.height = 4096;
            this.spotLight.shadow.camera.near = 4;
            this.spotLight.shadow.camera.far = 2000;
            this.spotLight.shadow.camera.fov = 180;
            this.group.add(this.spotLight);
        }
    }

    initFloor() {
        this.floor.geometry.rotateX(-Math.PI / 2)
        this.floor.position.set(5.2, 1.4, 0)
        this.floor.receiveShadow = true;
        this.group.add(this.floor);
    }

    animator(model) {
        let mixer = new THREE.AnimationMixer(model)
        let animation = THREE.AnimationClip.findByName(this.animations, model.name);
        const action = mixer.clipAction(animation)
        action.play()
        this.mixer.push(mixer)
    }

    addPnj(withKhloe = false, part2 = false) {
        if (part2) {
            super.addModel(this.hubertAnimationTalking, this.group, 0.065, new THREE.Vector3(-5, 2, 0), new THREE.Vector3(0, Math.PI / 3, 0));
            this.animator(this.hubertAnimationTalking);

            super.addModel(this.moniqueAnimationPart2, this.group, 0.065, new THREE.Vector3(5, 2, -5), new THREE.Vector3(0, - Math.PI / 4, 0));
            this.animator(this.moniqueAnimationPart2);
        } else {
            if (withKhloe) {
                super.addModel(this.hubertAnimationTalking, this.group, 0.065, new THREE.Vector3(-5, 2, 0), new THREE.Vector3(0, Math.PI / 3, 0));
                this.animator(this.hubertAnimationTalking);

                super.addModel(this.moniqueAnimationPart1, this.group, 0.065, new THREE.Vector3(5, 2, -5), new THREE.Vector3(0, - Math.PI / 4, 0));
                this.animator(this.moniqueAnimationPart1);
            } else {
                super.addModel(this.hubertAnimationTalking, this.group, 0.065, new THREE.Vector3(5, 2, 15), new THREE.Vector3(0, 5 * Math.PI / 6, 0));
                this.animator(this.hubertAnimationTalking);

                super.addModel(this.moniqueAnimationPart1, this.group, 0.065, new THREE.Vector3(0, 2, 2), new THREE.Vector3(0, Math.PI / 6, 0));
                this.animator(this.moniqueAnimationPart1);
            }
        }

        if (withKhloe) {
            super.addModel(this.khloeAnimation, this.group, 0.065, new THREE.Vector3(5, 2, 15), new THREE.Vector3(0, Math.PI, 0));
            this.animator(this.khloeAnimation);
        }
    }

    removePnj(model) {
        this.mixer = [];
        this.group.remove(model);
    }

    displayTwoTrashes() {
        this.yellowTrashes.scale.set(0, 0, 0);
        this.threeTrashes = false;
    }

    displayThreeTrashes() {
        this.yellowTrashes.scale.set(1, 1, 1);
        this.threeTrashes = true;
    }

    removeTrajectory() {
        this.line?.geometry.dispose();
        this.line?.material.dispose();
        this.group.remove(this.line);
        this.curve = null
    }

    drawTrajectory() {
        this.removeTrajectory()
        this.curve = new THREE.CatmullRomCurve3(this.curvePoints);

        const material = new THREE.MeshLambertMaterial({ color: 0x6BC1FF, opacity: 0.3, wireframe: false, transparent: true });
        this.splineGeometry = new THREE.TubeGeometry(this.curve, 64, 0.05);
        this.line = new THREE.Mesh(
            this.splineGeometry,
            material
        );

        this.group.add(this.line);

        this.direction = new THREE.Vector3();
        this.position = this.currentGarbage.position.clone();
        this.elapsedTime = 0;
    }

    animate(deltaTime) {
        if (this.mixer.length) {
            this.mixer.forEach(mixer => {
                mixer.update(deltaTime)
            });
        }

        if (this.currentGarbage != null) {
            this.currentGarbage.rotation.y += 0.005;
        }

        if (this.animateGarbage) {
            const looptime = 9 * 1000;
            this.elapsedTime += deltaTime;
            const t = ((this.elapsedTime * 3000) % looptime) / looptime;

            this.splineGeometry.parameters.path.getPointAt(t, this.position);

            // interpolation
            const segments = this.splineGeometry.tangents.length;
            const pickt = t * segments;
            const pick = Math.floor(pickt);
            const pickNext = (pick + 1) % segments;

            if (pick > 0 && pickNext === 0) {
                this.animateGarbage = false;
                this.line.material.opacity = 0.3;
                this.trashGoalasso()
                return;
            }
            this.currentGarbage.position.copy(this.position);
        }
    }
    displayTextUI() {
        super.displayTextUI();

        Json.getData('../data/firstScene.json', (data) => {
            uiManager.displayDialog(data['before-interaction'], () => {
                this.setOffset(0);
                this.removePnj();
                this.firstInteraction();
                this.removePnj(this.hubertAnimationTalking)
                this.removePnj(this.moniqueAnimationPart1)
                uiManager.displayInteraction(data['first-interaction'], () => {
                    document.getElementsByClassName('step')[0].style.display = 'none';
                    this.setPlaceConfiguration();
                    this.addPnj(true);
                    this.removeTrajectory()
                    this.currentGarbage.position.set(0, -5, 2.5);

                    this.setOffset(500);
                    uiManager.displayDialog(data['between-interaction'], () => {
                        this.removePnj(this.hubertAnimationTalking)
                        this.removePnj(this.moniqueAnimationPart1)
                        this.removePnj(this.khloeAnimation)
                        document.getElementsByClassName('step')[0].style.display = 'flex';

                        this.setOffset(0);
                        this.currentInteraction = 2;
                        sceneManager.setSceneConfig({ x: -7, y: 12, z: 2.5 }, { target: new THREE.Vector3(20, 5, 2.5), enableZoom: false, enableRotate: false });
                        this.shotDirection();
                        this.displayThreeTrashes();
                        this.currentGarbage.position.set(0, 8, 2.5);
                        const slingshot = document.getElementsByClassName('slingshot')[0];
                        slingshot.style.display = 'flex';
                        slingshot.classList.remove('double');
                        slingshot.classList.add('triple');
                        uiManager.displayInteraction(data['second-interaction'], () => {
                            document.getElementsByClassName('step')[0].style.display = 'none';
                            this.setPlaceConfiguration();
                            this.removeTrajectory()
                            this.setOffset(500);
                            this.addPnj(true, true);
                            uiManager.displayDialog(data['after-interaction'], () => {
                                uiManager.displaySceneConclusion(data['scene-conclusion'], () => {
                                    ExperienceManager.backToTown();
                                });
                            })
                        }, FIRST_SCENE);
                    })
                })
            }, FIRST_SCENE, true);
        });
    }

    firstInteraction() {
        document.getElementsByClassName('slingshot')[0].style.display = 'flex';
        document.getElementsByClassName('step')[0].style.display = 'flex';
        document.getElementById('step-value').style.opacity = '1';
        sceneManager.setSceneConfig({ x: -7, y: 12, z: -2 }, { target: new THREE.Vector3(20, 5, -2), enableRotate: false, enableZoom: false });
        this.nextGarbage();
        this.shotDirection();
    }

    nextGarbage() {
        if (this.shotCount === this.garbagesArray.length) {
            document.getElementsByClassName('slingshot')[0].style.display = 'none';
            this.line.material.opacity = 0;
            clearTimeout(this.clickTimeout);
            return;
        }

        this.currentGarbage = this.garbagesArray[this.shotCount];
        if (this.threeTrashes) {
            this.currentGarbage.position.set(0, 8, 2.5);
        } else {
            this.currentGarbage.position.set(0, 8, -2);
        }
        this.shotCount++;

        setTimeout(() => {
            if (this.shotCount > 1) {
                document.getElementById('step-value').style.opacity = '0';
                setTimeout(() => {
                    document.getElementById('step-value').innerHTML = this.shotCount;
                    document.getElementById('step-value').style.opacity = '1';
                }, 400);
            }
        }, 1000);
    }

    resetGarbage() {
        this.currentGarbage = this.garbagesArray[this.shotCount - 1];


        if (this.threeTrashes) {
            this.currentGarbage.position.set(0, 8, 2.5);
        } else {
            this.currentGarbage.position.set(0, 8, -2);
        }
    }

    trashGoalasso() {
        switch (this.targetTrash) {
            case 'yellow':
                this.garbageInteraction(['yogurt']);
                break;
            case 'green':
                this.garbageInteraction(['bottle'], this.currentInteraction === 1 && this.currentGarbage.name === 'yogurt');
                break;
            case 'black':
                this.garbageInteraction(['trashBag', 'pot'], this.currentInteraction === 1 && this.currentGarbage.name === 'yogurt');
                break;
            default:
                break;
        }
    }

    garbageInteraction(currentGarbageNames, shouldDispatchOnFailed = false) {
        if (!this.canClick) {
            return;
        }

        clearTimeout(this.clickTimeout);

        if (currentGarbageNames.includes(this.currentGarbage.name)) {
            SoundManager.playSound(WIN);
            this.canClick = false;
            this.currentGarbage?.geometry.dispose();
            this.currentGarbage?.material.dispose();
            this.group.remove(this.currentGarbage);
            window.dispatchEvent(new Event('interaction_continue'));
            this.clickTimeout = setTimeout(() => {
                window.dispatchEvent(new Event('interaction_continue'));
                this.canClick = true;
            }, 1000);
            this.nextGarbage();
        } else {
            SoundManager.playSound(FAIL);
            this.resetGarbage();
            document.getElementsByClassName('title-interaction')[0].innerHTML = 'Oups, recommence !';
            document.getElementsByClassName('text')[3].innerHTML = 'Tu t’es trompé de poubelle !';

            if (shouldDispatchOnFailed) {
                document.getElementsByClassName('slingshot')[0].style.display = 'none';
                window.dispatchEvent(new Event('interaction_continue'));
            }
        }
    }

    changeDirection(trash) {

        switch (trash) {
            case 'yellow':
                this.curvePoints = this.yellowTrajectory;
                break;
            case 'black':

                if (!this.threeTrashes) {
                    this.curvePoints = this.blackTrajectory1;
                } else {
                    this.curvePoints = this.blackTrajectory2;
                }
                break;
            case 'green':
                if (!this.threeTrashes) {
                    this.curvePoints = this.greenTrajectory1;
                } else {
                    this.curvePoints = this.greenTrajectory2;
                }
                break;
            default:
                break;
        }

    }

    shotDirection() {
        document.querySelector('.slingshot').addEventListener('mousedown', () => {
            document.querySelector('.slingshot-instruction').innerHTML = "vise & relache"
        });
        document.querySelector('.slingshot').addEventListener('mouseup', () => {
            document.querySelector('.slingshot-instruction').innerHTML = "appuie"
        });
        document.querySelector('.slingshot-aera.yellow').addEventListener('mouseenter', () => {
            if (!this.animateGarbage) {
                this.changeDirection('yellow');
                this.drawTrajectory()
            }
        });

        document.querySelector('.slingshot-aera.green').addEventListener('mouseenter', () => {
            if (!this.animateGarbage) {
                this.changeDirection('green');
                this.drawTrajectory()
            }
        });

        document.querySelector('.slingshot-aera.black').addEventListener('mouseenter', () => {
            if (!this.animateGarbage) {
                this.changeDirection('black');
                this.drawTrajectory()
            }
        })

        document.querySelector('.slingshot-aera.yellow').addEventListener('mouseup', () => {
            this.elapsedTime = 0;
            this.animateGarbage = true;
            this.targetTrash = 'yellow';
            this.line.material.opacity = 0;
        });

        document.querySelector('.slingshot-aera.green').addEventListener('mouseup', () => {
            this.elapsedTime = 0;
            this.animateGarbage = true;
            this.targetTrash = 'green';
            this.line.material.opacity = 0;
        });

        document.querySelector('.slingshot-aera.black').addEventListener('mouseup', () => {
            this.elapsedTime = 0;
            this.animateGarbage = true;
            this.targetTrash = 'black';
            this.line.material.opacity = 0;
        })
    }
}