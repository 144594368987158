import uiManager from '../utils/uiManager';
import Json from '../helpers/json';
import Intro from './intro';
import ExperienceManager, {CONCLUSION} from '../utils/experienceManager';
import SoundManager from "../utils/soundManager";

export default class EndScene extends Intro {
    constructor(props) {
        super(props);
        this.setOffset(0);
    }

    displayTextUI() {
        Json.getData('../data/conclusion.json', (data) => {
            uiManager.displayExplanation(data, () => {
                SoundManager.pauseBackgroundSound();
                SoundManager.playSound('../sounds/bande_organise.ogg', true, 0.1);
                ExperienceManager.launchScene(CONCLUSION);
            }, true);
        });
    }
}