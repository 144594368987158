import gsap from 'gsap/all';
const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }

class CursorSingleton {
    constructor() {
        this.circle = document.getElementById('cursor');
        this.pos = {x: pos.x, y: pos.y};
        this.textElement = this.circle.querySelector('#cursor-text');
        this.backgroundElement = this.circle.querySelector('#cursor-background');
    }

    init() {
        const speed = 0.35;
        gsap.set(cursorSingleton.circle, { xPercent: -50, yPercent: -50 });
        const xSet = gsap.quickSetter(cursorSingleton.circle, "x", "px");
        const ySet = gsap.quickSetter(cursorSingleton.circle, "y", "px");

        window.addEventListener("pointermove", e => {
            cursorSingleton.pos.x = e.x
            cursorSingleton.pos.y = e.y
        });

        gsap.ticker.add(() => {
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
            pos.x += (cursorSingleton.pos.x - pos.x) * dt;
            pos.y += (cursorSingleton.pos.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });

        this.cursorSingletonHoverTl = gsap.timeline();
        this.cursorSingletonHoverTl.to(cursorSingleton.circle, {
            scale: 1.2,
            duration: 0
        });

        this.cursorSingletonHoverTl.from(cursorSingleton.textElement, {
            opacity: 0,
            duration: 0,
            display: 'none'
        });

        this.cursorSingletonHoverTl.to(cursorSingleton.backgroundElement, {
            opacity: 0,
            duration: 0,
            display: 'none'
        });

        this.cursorSingletonHoverTl.pause();
        this.cursorSingletonHoverTl.reverse();
        this.mouseenter = null;
        this.mouseentercallback = null;
        this.mouseleave = null;
        this.mouseleavecallback = null;
        this.mousemove = null;
        this.mousemovecallback = null;
        this.links = null;
    }

    mouseEnter() {
        if (this.mouseentercallback) {
            this.mouseentercallback();
        }
        this.cursorSingletonHoverTl.play();
    }

    mouseLeave() {
        if (this.mouseleavecallback) {
            this.mouseleavecallback()
        }
        this.cursorSingletonHoverTl.reverse();
    }

    mouseMove() {
        if (this.mousemovecallback()) {
            this.cursorSingletonHoverTl.play();
        } else {
            this.cursorSingletonHoverTl.reverse();
        }
    }

    setText(text) {
        this.textElement.innerText = text;
    }

    registerCursor(links, text, onWindow = null, mouseEnter = null, mouseLeave = null) {
        this.setText(text);
        this.links = links;
        this.mouseenter = this.mouseEnter.bind(this);
        this.mouseentercallback = mouseEnter;
        this.mouseleave = this.mouseLeave.bind(this);
        this.mouseleavecallback = mouseLeave;
        this.links.forEach(link => {
            link.addEventListener('mouseleave', this.mouseleave);
            link.addEventListener('mouseenter', this.mouseenter);
        });
        if (onWindow) {
            this.mousemove = this.mouseMove.bind(this);
            this.mousemovecallback = onWindow;
            window.addEventListener('pointermove', this.mousemove);
        }
    }

    unregisterCursor() {
        this.setText('');
        this.links.forEach(link => {
            link.removeEventListener('mouseleave', this.mouseleave);
            link.removeEventListener('mouseenter', this.mouseenter);
        });
        if (this.mousemove) {
            window.removeEventListener('pointermove', this.mousemove);
        }
        this.cursorSingletonHoverTl.reverse();
        this.mouseenter = null;
        this.mouseentercallback = null;
        this.mouseleave = null;
        this.mouseleavecallback = null;
        this.mouseleave = null;
        this.mouseleavecallback = null;
    }
}

const cursorSingleton = new CursorSingleton();
export { cursorSingleton };