import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Intro from '../places/intro';
import Village from '../places/village';
import SceneOne from '../places/scene_01';
import { EventDispatcher } from 'three';
import {
    CONCLUSION,
    END_SCENE,
    FIRST_SCENE,
    INTRO_SCENE,
    SECOND_SCENE,
    THIRD_SCENE,
    VILLAGE_SCENE
} from '../utils/experienceManager';
import SceneTwo from '../places/scene_02';
import SceneThree from '../places/scene_03';
import Debug from '../helpers/debug';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import EndScene from '../places/end_scene';
import Conclusion from '../places/conclusion';

let frameCount = 0;
class SceneManager extends EventDispatcher {
    constructor() {
        super();
        this.initElements();
        this.initEvents();
    }

    initElements() {
        this.sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.canvas = document.querySelector('canvas.webgl');
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(45, this.sizes.width / this.sizes.height, 1, 2000);
        this.controls = new OrbitControls(this.camera, this.canvas);

        //this.controls.listenToKeyEvents(window);
        //this.controls.keyPanSpeed = 30;

        this.controls.enableRotate = false;
        this.controls.maxDistance = 15;
        this.controls.minDistance = 5;
        this.clock = new THREE.Clock();
        this.previousTime = 0;
        this.lights = new THREE.DirectionalLight(0xffffff, 0.6);
        this.renderer = null;
        this.places = {};
        this.activePlace = null;
        this.scene.fog = new THREE.Fog(0xffffe4, 0.1, 0);
        this.floor = null;

        // To move to mouse or cursor utils
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.mouseIntersect = null;
        this.elementIntersect = null;
        this.elementsToIntersect = null;

        this.composer = null;

        this.name = null;
        this.spotLightDebug = null
        //Debug
        //this.debug = new Debug();
    }

    initEvents() {
        //setInterval(() => console.log(this.camera.position, this.controls.target), 1500);

        const beforeUnloadListener = () => {
            window.removeEventListener('resize', () => this.onWindowResize());
        };
        window.addEventListener('beforeunload', beforeUnloadListener, { once: true });
    }

    initScene() {
        // Renderer
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha: true,
            antialias: true
        });

        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFShadowMap;
        this.renderer.shadowMap.bias = 0.05;
        this.renderer.shadowMap.darkness = 0.5;
        this.renderer.shadowMap.width = 4096;
        this.renderer.shadowMap.height = 4096;
        this.renderer.shadowMap.near = 4;
        this.renderer.shadowMap.far = 2000;

        this.scene.add(this.camera);
        this.camera.position.set(15, 10, 15);

        this.scene.add(this.lights);
        const spotLight = new THREE.SpotLight(0xffffff, 0.7);
        spotLight.position.set(1000, 1000, 1000);

        this.scene.add(spotLight);

        this.controls.target.x = 10.0;
        this.controls.target.y = 5.0;
        this.controls.target.z = 0.0;
        this.controls.enableDamping = true;

        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        this.composer = new EffectComposer(this.renderer);

        let geometry = new THREE.PlaneGeometry( 1000, 1000, 1, 1 );
        geometry.rotateX(-Math.PI / 2)

        let material = new THREE.ShadowMaterial({
            opacity: 0.5,
            color: 0x000000,
        });
        // var material = new THREE.MeshBasicMaterial({
        //     opacity: 1,
        //     color: 0x00FF00,
        // });

        this.floor = new THREE.Mesh(geometry, material);
        this.floor.receiveShadow = true;
        this.scene.add(this.floor);
        window.addEventListener('resize', () => this.onWindowResize(), false);
        this.mouseHandler();
        this.animate();
    }

    fogModifier(near, far) {
        this.scene.fog.near = near;
        this.scene.fog.far = far;
        // FogExp2(color, density)
    }
    /**
     * Fires when the document view (window) has been resized
     */
    onWindowResize() {
        clearTimeout(this.resizeTimout);
        this.resizeTimout = setTimeout(() => {
            // Update sizes
            this.sizes.width = window.innerWidth - this.activePlace?.offset;
            this.sizes.height = window.innerHeight;

            // Update camera
            this.camera.aspect = this.sizes.width / this.sizes.height;
            this.camera.updateProjectionMatrix();

            // Update renderer
            this.renderer.setSize(this.sizes.width, this.sizes.height);
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        }, 16);
    }

    animate() {
        const tick = () => {
            const elapsedTime = this.clock.getElapsedTime();
            const deltaTime = elapsedTime - this.previousTime;
            this.previousTime = elapsedTime;

            // Render
            this.render();

            // Update controls
            if (this.controls) {
                this.controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
            }

            this.raycaster.setFromCamera(this.mouse, this.camera)
            if (this.elementsToIntersect) {

                this.elementIntersect = this.raycaster.intersectObjects(this.elementsToIntersect)
            }

            if (this.elementIntersect?.length) {
                this.mouseIntersect = this.elementIntersect[0]

            }
            else {
                this.mouseIntersect = null
            }

            this.activePlace?.animate(deltaTime);


            this.composer.render(deltaTime);

            this.activePlace?.animate(deltaTime);

            //this.debug.update();


            // Call tick again on the next frame
            window.requestAnimationFrame(tick);
        }

        tick();
    }



    mouseHandler() {
        window.addEventListener('mousemove', (event) => {
            this.mouse.x = event.clientX / this.sizes.width * 2 - 1
            this.mouse.y = - (event.clientY / this.sizes.height) * 2 + 1
        })
    }

    render() {
        this.renderer.render(this.scene, this.camera);
    }

    /**
     *
     * @param place
     * @returns {Intro|EndScene|SceneTwo|null|SceneThree|Conclusion|Village|SceneOne}
     */
    getNewPlace(place) {
        switch (place) {
            case INTRO_SCENE:
                return new Intro();
            case VILLAGE_SCENE:
                return new Village();
            case FIRST_SCENE:
                return new SceneOne();
            case SECOND_SCENE:
                return new SceneTwo();
            case THIRD_SCENE:
                return new SceneThree();
            case END_SCENE:
                return new EndScene();
            case CONCLUSION:
                return new Conclusion();
        }
        return null;
    }

    /**
     *
     * @param place
     * @param lastScene
     */
    setActivePlace(place, lastScene) {
        if (!this.places.hasOwnProperty(place)) {
            this.places[place] = this.getNewPlace(place);
            this.activePlace = this.places[place];
            this.activePlace.setLastScene(lastScene);
            this.activePlace.setPlaceConfiguration();
            this.assetLoader.loadModel(this.places[place], () => this.activePlace?.addToSceneManager());
            this.activePlace.displayTextUI();
        } else {
            this.activePlace = this.places[place];
            this.activePlace.setLastScene(lastScene);
            this.activePlace.setPlaceConfiguration();
            this.activePlace?.addToSceneManager();
            this.activePlace.displayTextUI();
        }
    }

    removeActivePlace() {
        this.activePlace?.removeSceneFromManager();
        this.activePlace = null;
    }

    setSceneConfig(cameraConfig, controlsConfig = {}) {
        sceneManager.camera.position.set(cameraConfig.x, cameraConfig.y, cameraConfig.z);
        Object.keys(controlsConfig).forEach((attribute) => sceneManager.controls[attribute] = controlsConfig[attribute]);
    }
}

const sceneManager = new SceneManager();

export { sceneManager }
