import Place from '../classes/place';
import { sceneManager } from '../classes/sceneManager';
import uiManager from '../utils/uiManager';
import { CONCLUSION } from '../utils/experienceManager';
import * as THREE from 'three';

export default class Conclusion extends Place {
    constructor() {
        super(CONCLUSION, ['plan_4.1.fbx', 'hubertAnimationTwerk.fbx', 'kamilleAnimationDancing.fbx', 'khloeAnimationDance.fbx', 'killianAnimationDance.fbx', 'lukasAnimationDance.fbx', 'moniqueAnimationDance.fbx', 'pascalAnimationDance.fbx', 'veroniqueAnimationDance.fbx']);
        this.cameraConfig = { x: 28, y: 49, z: 7 };
        this.controlConfig = {
            ...this.controlConfig,
            target: new THREE.Vector3(5, 31, 34),
            maxDistance: 800,
            minDistance: 20,
            enableRotate: true,
            enableZoom: true
        };
        this.setOffset(0);
        sceneManager.fogModifier(0.1, 0)
        this.animateCamera = false;
        this.mixer = [];
    }

    setLightConfiguration() {
        super.setLightConfiguration();

        if (!this.hemiLight) {
            this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xf1975d, .3);
            this.hemiLight.name = 'hemiLight';
            this.hemiLight.position.set(50, 100, 50);
            this.group.add(this.hemiLight);
            this.spotLight = new THREE.SpotLight(0xc4844a, 1);
            this.spotLight.position.set(-70, 134, -134);
            this.spotLight.castShadow = true;
            // sceneManager.spotLightDebug = this.spotLight
            this.spotLight.shadow.mapSize.width = 4096;
            this.spotLight.shadow.mapSize.height = 4096;
            this.spotLight.shadow.camera.near = 4;
            this.spotLight.shadow.camera.far = 2000;
            this.spotLight.shadow.camera.fov = 180;
            this.group.add(this.spotLight);
        }
    }

    addModelToPlace(model) {

        // not uniform render on pnjs' models
        // if (model.name != 'plan') {
        //     model.traverse(function (child) {
        //         if (child.isMesh) {      
        //             child.receiveShadow = true;
        //         }
        //     });
        // }
      
        switch (model.name) {
            case 'plan': {
                const textures = {};
                model.traverse(function (child) {
                    if (child.isMesh) {
                        const textureName = child.material.name.split('_')[0];
                        if (textureName !== '') {
                            if (!textures.hasOwnProperty((textureName))) {
                                textures[textureName] = child.material.map;
                            }
                        }
                    }
                });
                model.traverse(function (child) {
                    if (child.isMesh) {
                        const textureName = child.material.name.split('_')[0];
                        if (textureName === '') {
                            child.material.map = textures[child.name.split('_')[0]];
                        }
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                });
                super.addModelToPlace(model);
            }
                break;
            case 'hubertAnimationTwerk': {
                this.hubertAnimationTwerk = model;
            } break;
            case 'kamilleAnimationDancing': {
                this.kamilleAnimationDancing = model;
            } break;
            case 'khloeAnimationDance': {
                this.khloeAnimationDance = model;
            } break;
            case 'killianAnimationDance': {
                this.killianAnimationDance = model;
            } break;
            case 'lukasAnimationDance': {
                this.lukasAnimationDance = model;
            } break;
            case 'moniqueAnimationDance': {
                this.moniqueAnimationDance = model;
            } break;
            case 'pascalAnimationDance': {
                this.pascalAnimationDance = model;
            } break;
            case 'veroniqueAnimationDance': {
                this.veroniqueAnimationDance = model;
            } break;
        }
    }

    displayTextUI() {
        super.displayTextUI();
        uiManager.displayEndExperience();
    }

    addToSceneManager() {
        this.addPnj();
        super.addToSceneManager();
    }

    addPnj() {
        super.addModel(this.hubertAnimationTwerk, this.group, 0.05, new THREE.Vector3(-18, 10, 64), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.hubertAnimationTwerk);
        super.addModel(this.kamilleAnimationDancing, this.group, 0.05, new THREE.Vector3(-28, 10, 54), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.kamilleAnimationDancing);
        super.addModel(this.khloeAnimationDance, this.group, 0.05, new THREE.Vector3(-28, 10, 64), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.khloeAnimationDance);
        super.addModel(this.killianAnimationDance, this.group, 0.05, new THREE.Vector3(-18, 10, 54), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.killianAnimationDance);
        super.addModel(this.lukasAnimationDance, this.group, 0.05, new THREE.Vector3(-7, 10, 38), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.lukasAnimationDance);
        super.addModel(this.moniqueAnimationDance, this.group, 0.05, new THREE.Vector3(-3, 10, 50), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.moniqueAnimationDance);
        super.addModel(this.pascalAnimationDance, this.group, 0.05, new THREE.Vector3(-10, 10, 65), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.pascalAnimationDance);
        super.addModel(this.veroniqueAnimationDance, this.group, 0.05, new THREE.Vector3(-22, 10, 44), new THREE.Vector3(0, - 4 * Math.PI / 3, 0));
        this.animator(this.veroniqueAnimationDance);
    }

    animator(model) {
        let mixer = new THREE.AnimationMixer(model);
        let animation = THREE.AnimationClip.findByName(this.animations, model.name);
        const action = mixer.clipAction(animation);
        action.play();
        this.mixer.push(mixer);
    }

    animate(deltaTime) {
        super.animate(deltaTime);
       
        if (this.composer) {
            this.composer.render();
        }

        if (this.mixer.length) {
            this.mixer.forEach(mixer => {
                mixer.update(deltaTime);
            });
        }
    }
}
