import * as THREE from 'three';

export default class SoundManager {
    constructor() {
        this.listener = new THREE.AudioListener();
        this.audioLoader = new THREE.AudioLoader();

        // default audio sound
        this.backgroundSound = new THREE.Audio(this.listener);
        this.audioLoader.load(GLOBAL_SOUND, (buffer) => {
            this.backgroundSound.setBuffer(buffer);
            this.backgroundSound.setLoop(true);
            this.backgroundSound.setVolume(0.05);
        });
        this.loadedSound = {};
    }

    static startBackgroundSound() {
        soundManagerSingleton.backgroundSound.play();
    }

    static pauseBackgroundSound() {
        soundManagerSingleton.backgroundSound.pause();
    }

    static playSound(sound, loop = false, volume = 0.4) {
        if (soundManagerSingleton.loadedSound.hasOwnProperty(sound)) {
            soundManagerSingleton.loadedSound[sound].setVolume(volume);
            soundManagerSingleton.loadedSound[sound].play();
        }
        const audio = new THREE.Audio(soundManagerSingleton.listener);
        soundManagerSingleton.audioLoader.load(sound, (buffer) => {
            audio.setBuffer(buffer);
            audio.setVolume(volume);
            audio.setLoop(loop);
            soundManagerSingleton.loadedSound[sound] = audio;
            soundManagerSingleton.loadedSound[sound].play();
        });
    }

    static pauseSound(sound) {
        if (soundManagerSingleton.loadedSound.hasOwnProperty(sound)) {
            soundManagerSingleton.loadedSound[sound].pause();
        }
    }

    static setVolume(sound, volume) {
        if (soundManagerSingleton.loadedSound.hasOwnProperty(sound)) {
            soundManagerSingleton.loadedSound[sound].setVolume(volume);
        }
    }

    static mute() {
        Object.values(soundManagerSingleton.loadedSound).forEach((sound) => {
            sound.oldVolume = sound.getVolume();
            sound.setVolume(0);
        });

        soundManagerSingleton.backgroundSound.oldVolume = soundManagerSingleton.backgroundSound.getVolume();
        soundManagerSingleton.backgroundSound.setVolume(0);
    }

    static unmute() {
        Object.values(soundManagerSingleton.loadedSound).forEach((sound) => {
            sound.setVolume(sound.oldVolume);
        });

        soundManagerSingleton.backgroundSound.setVolume(soundManagerSingleton.backgroundSound.oldVolume);
    }
}

const GLOBAL_SOUND = '../sounds/global_sound.ogg';
const FAIL = '../sounds/fail.ogg';
const WIN = '../sounds/win.ogg';
const POP_MESSAGE = '../sounds/message.ogg';
const DOOR = '../sounds/door.ogg';
export { GLOBAL_SOUND, FAIL, WIN, POP_MESSAGE, DOOR }

const soundManagerSingleton = new SoundManager();