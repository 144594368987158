import Place from '../classes/place';
import uiManager from '../utils/uiManager';
import Json from '../helpers/json';
import ExperienceManager, {
    END_SCENE,
    THIRD_SCENE,
} from '../utils/experienceManager';
import * as THREE from 'three';
import {sceneManager} from '../classes/sceneManager';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {OutlinePass} from 'three/examples/jsm/postprocessing/OutlinePass';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {GammaCorrectionShader} from 'three/examples/jsm/shaders/GammaCorrectionShader';
import {cursorSingleton} from '../uiComponents/cursor';
import SoundManager, {DOOR} from "../utils/soundManager";

export default class SceneThree extends Place {
    constructor() {
        super(THIRD_SCENE,
            ['scene03_3.0.fbx', 'hubertAnimationTalking.fbx', 'lukasAnimation.fbx', 'killianAnimation.fbx', 'veroniqueAnimationDance.fbx'],
            ['hubert_embarrassed', 'hubert_happy', 'veronique', 'lukas']);
        this.cameraConfig = { x: 36, y: 38, z: -44 };
        this.controlConfig = {
            ...this.controlConfig,
            target: new THREE.Vector3(-18, -2, 12),
            maxDistance: 100,
            minDistance: 5,
            enableRotate: false,
            enableZoom: false
        };
        this.setOffset(500);
        this.mixer = [];

        this.listener = new THREE.AudioListener();
        this.audioLoader = new THREE.AudioLoader();
        this.globalSoundSuppressed = new THREE.Audio(this.listener);
        this.audioLoader.load( '../sounds/all_musics.ogg', (buffer) => {
            this.globalSoundSuppressed.setBuffer(buffer);
            this.globalSoundSuppressed.setLoop(true);
            this.globalSoundSuppressed.setVolume(0.5);
            this.globalSoundSuppressed.play();
        });

        this.globalSound = new THREE.Audio(this.listener);
        this.audioLoader.load( '../sounds/bande_organise.ogg', (buffer) => {
            this.globalSound.setBuffer(buffer);
            this.globalSound.setLoop(true);
            this.globalSound.setVolume(0);
            this.globalSound.play();
        });

        this.soundSecondDoor = new THREE.PositionalAudio(this.listener);
        this.audioLoader.load('../sounds/joe_dassin.ogg', (buffer) => {
            this.soundSecondDoor.setBuffer(buffer);
            this.soundSecondDoor.setLoop(true);
            this.soundSecondDoor.setVolume(0);
            this.soundSecondDoor.setRefDistance(20);
            this.soundSecondDoor.play();

        });

        this.soundThirdDoor = new THREE.PositionalAudio(this.listener);
        this.audioLoader.load('../sounds/the_beatles.ogg', (buffer) => {
            this.soundThirdDoor.setBuffer(buffer);
            this.soundThirdDoor.setLoop(true);
            this.soundThirdDoor.setVolume(0);
            this.soundThirdDoor.setRefDistance(20);
            this.soundThirdDoor.play();
        });

        this.canHover = false;

        this.floor = new THREE.Mesh(
            new THREE.PlaneGeometry(50, 20, 1, 1),
            new THREE.ShadowMaterial({
                opacity: 0.5,
                color: 0x000000,
            }),
        );
        this.initFloor()
    }

    displayTextUI() {
        super.displayTextUI();

        Json.getData('../data/thirdScene.json', (data) => {
            uiManager.displaySceneExplanation(data['before-interaction'], () => {
                this.setOffset(0);
                this.focusOnDoors();
                uiManager.displayInteraction(data['first-interaction'], () => {
                    this.setOffset(500);
                    this.firstDoorModel.position.x -= 100;
                    this.firstDoorModel.position.z += 70;
                    this.secondDoorModel.position.x -= 100;
                    this.secondDoorModel.position.z += 70;
                    this.thirdDoorModel.position.x -= 100;
                    this.thirdDoorModel.position.z += 70;
                    this.firstDoorModel.rotation.set(0, -8 * Math.PI / 3, 0);
                    this.secondDoorModel.rotation.set(0, -8 * Math.PI / 3, 0);
                    this.thirdDoorModel.rotation.set(0, -8 * Math.PI / 3, 0);
                    cursorSingleton.unregisterCursor();
                    this.globalSound.setVolume(0.1);
                    this.setPlaceConfiguration();
                    this.addPnj(true);
                    uiManager.displayDialog(data['after-interaction'], () => {
                        SoundManager.startBackgroundSound();
                        this.globalSound.pause();
                        uiManager.displaySceneConclusion(data['scene-conclusion'], () => {
                            ExperienceManager.launchScene(END_SCENE);
                        });
                    }, THIRD_SCENE);
                })
            }, THIRD_SCENE);
        });
    }

    focusOnDoors() {
        this.removePnj();
        sceneManager.setSceneConfig(
            { x: -11, y: 7, z: -23 },
            { target: new THREE.Vector3(-11, 7, 8), enableZoom: false, enableRotate: false, maxDistance: 100, minDistance: 5 },
        );
        this.globalSoundSuppressed.pause();
        this.soundSecondDoor.setVolume(0.1);
        this.soundThirdDoor.setVolume(0.1);
        this.bindedHover = this.onHover.bind(this);
        this.bindedClick = this.onClick.bind(this);
        window.addEventListener('mousemove', this.bindedHover);
        window.addEventListener('click', this.bindedClick);
        window.addEventListener('can_hover', () => {
            this.canHover = true;
        }, {once:true});
        sceneManager.elementsToIntersect = [...this.secondDoorModel.children, ...this.thirdDoorModel.children];
        cursorSingleton.registerCursor([], 'choisir', this.onDoorHover.bind(this));
    }

    addToSceneManager() {
        this.addPnj();
        super.addToSceneManager();
    }

    addPnj(withOther = false) {
        super.addModel(this.hubertAnimationTalking, this.group, 0.05, new THREE.Vector3(-13, 1, -12));
        this.animator(this.hubertAnimationTalking);

        if (withOther) {
            super.addModel(this.lukasAnimation, this.group, 0.05, new THREE.Vector3(-19,1, -2), new THREE.Vector3(0, Math.PI, 0));
            this.animator(this.lukasAnimation);
            super.addModel(this.killianAnimation, this.group, 0.05, new THREE.Vector3(-4,1, -2), new THREE.Vector3(0, Math.PI, 0));
            this.animator(this.killianAnimation);
            super.addModel(this.veroniqueAnimation, this.group, 0.05, new THREE.Vector3(7,1, -2), new THREE.Vector3(0, Math.PI, 0));
            this.animator(this.veroniqueAnimation);
        }
    }

    removePnj() {
        this.mixer = [];
        this.group.remove(this.hubertAnimationTalking);
        this.group.remove(this.lukasAnimation);
        this.group.remove(this.killianAnimation);
        this.group.remove(this.veroniqueAnimation);
    }

    setLightConfiguration() {
        super.setLightConfiguration();

        if (!this.hemiLight) {
            this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xf1975d, .2);
            this.hemiLight.name = 'hemiLight';
            this.hemiLight.position.set(50, 100, 50);
            this.group.add(this.hemiLight);
            this.spotLight = new THREE.SpotLight(0xc4844a, 0.3);
            this.spotLight.position.set(11, 43, -28);
            this.spotLight.castShadow = true;
            this.spotLight.shadow.mapSize.width = 4096;
            this.spotLight.shadow.mapSize.height = 4096;
            this.spotLight.shadow.camera.near = 4;
            this.spotLight.shadow.camera.far = 2000;
            this.spotLight.shadow.camera.fov = 180;
            this.group.add(this.spotLight);
        }
    }
    
    initFloor() {
        this.floor.geometry.rotateX(-Math.PI / 2)
        this.floor.position.set(-4, 1, -10)
        
        this.floor.receiveShadow = true;
        this.group.add(this.floor);
    }
    animator(model) {
        let mixer = new THREE.AnimationMixer(model);
        let animation = THREE.AnimationClip.findByName(this.animations, model.name);
        const action = mixer.clipAction(animation);
        action.play();
        this.mixer.push(mixer);
    }

    addModelToPlace(model) {
        SoundManager.pauseBackgroundSound();
        switch (model.name) {
            case 'scene03': {
                this.sceneModel = model;
                super.addModelToPlace(this.sceneModel);

                this.firstDoorModel = this.sceneModel.children.find((each) => each.name === 'appt01').children.find((each) => each.name === 'door01');
                this.secondDoorModel = this.sceneModel.children.find((each) => each.name === 'appt02').children.find((each) => each.name === 'door02');
                this.thirdDoorModel = this.sceneModel.children.find((each) => each.name === 'appt03').children.find((each) => each.name === 'door03');
                this.secondDoorModel.add(this.soundSecondDoor);
                this.thirdDoorModel.add(this.soundThirdDoor);

                this.setOutline();
            } break;
            case 'lukasAnimation': {
                this.lukasAnimation = model;
            } break;
            case 'killianAnimation': {
                this.killianAnimation = model;
            } break;
            case 'veroniqueAnimationDance': {
                this.veroniqueAnimation = model;
            } break;
            case 'hubertAnimationTalking': {
                this.hubertAnimationTalking = model;
            } break;
        }
    }

    setOutline() {
        if (!this.composer) {
            this.composer = new EffectComposer(sceneManager.renderer);
            let renderPass = new RenderPass(sceneManager.scene, sceneManager.camera);
            renderPass.setSize(window.innerWidth, window.innerHeight);
            this.composer.addPass(renderPass);

            this.outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), sceneManager.scene, sceneManager.camera);
            this.outlinePass.depthMaterial.skinning = false;
            this.outlinePass.prepareMaskMaterial.skinning = false;
            this.outlinePass.visibleEdgeColor.set(0xffffff);
            this.outlinePass.hiddenEdgeColor.set(0xffffff);
            this.outlinePass.edgeGlow = 0;
            this.outlinePass.edgeThickness = 1;
            this.outlinePass.edgeStrength = 5;
            this.composer.addPass(this.outlinePass);

            const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
            this.composer.addPass(gammaCorrectionPass);
        }
    }

    onDoorHover() {
        return this.canHover && !!sceneManager.mouseIntersect;
    }

    onHover() {
        if (this.canHover && sceneManager.mouseIntersect) {
            this.updatedSound = sceneManager.mouseIntersect.object.parent.children.find((each) => (each instanceof THREE.PositionalAudio));
            this.updatedSound.setVolume(0.9);
            this.outlinePass.selectedObjects = [sceneManager.mouseIntersect.object.parent];
        } else if (this.updatedSound) {
            this.updatedSound.setVolume(0.1);
            this.updatedSound = null;
            this.outlinePass.selectedObjects = [];
        }
    }

    onClick() {
        if (this.canHover && sceneManager.mouseIntersect) {
            SoundManager.playSound(DOOR);
            this.updatedSound.setVolume(0.1);
            this.updatedSound = null;
            sceneManager.elementsToIntersect = null;
            this.outlinePass.selectedObjects = [];
            this.soundSecondDoor.pause();
            this.soundThirdDoor.pause();
            window.dispatchEvent(new Event('interaction_continue'));
            window.removeEventListener('mousemove', this.bindedHover);
            window.removeEventListener('click', this.bindedClick);
            this.composer = null;
        }
    }

    animate(deltaTime) {
        super.animate(deltaTime);
        if (this.composer) {
            this.composer.render();
        }

        if (this.mixer.length) {
            this.mixer.forEach(mixer => {
                mixer.update(deltaTime);
            });
        }
    }
}