export default class Menu {
    constructor() {
        this.initEvents();
    }

    initEvents() {
        // open menu
        document.getElementById('open-menu').addEventListener('click', () => {
            document.getElementById('menu').classList.toggle('active');
        });

        // close menu
        document.getElementById('close-menu').addEventListener('click', () => {
            document.getElementById('menu').classList.toggle('active');
        });

        // close menu
        document.getElementsByClassName('back-menu')[0].addEventListener('click', () => {
            document.getElementById('menu').classList.toggle('active');
        });

        // close about page
        document.getElementById('close-about').addEventListener('click', () => {
            document.getElementById('about').classList.toggle('active');
        });

        // reload Experience
        document.getElementsByClassName('reload-experience')[0].addEventListener('click', () => {
            document.location.reload();
        });

        // about Page
        document.getElementsByClassName('about-button')[0].addEventListener('click', () => {
            document.getElementById('about').classList.toggle('active');
        });
    }
}