import AssetLoader from './utils/assetsLoader';
import {cursorSingleton} from './uiComponents/cursor';
import ExperienceManager from './utils/experienceManager';
import ResponsiveManager from './classes/responsiveManager';
import uiManager from './utils/uiManager';
import Menu from './classes/menu';


// Load elements we need
document.addEventListener('DOMContentLoaded', () => {
    cursorSingleton.init();
    new ResponsiveManager();
    new AssetLoader();
    uiManager.startExperience();
    ExperienceManager.init();
    new Menu();
}, {once:true});