import * as THREE from 'three';
import {sceneManager} from './sceneManager';

export default class Place {
    constructor(name, files, images = []) {
        this.name = name;
        this.files = files;
        this.group = new THREE.Group();
        this.group.name = name;
        this.offset = 0;
        this.lastScene = null;
        this.cameraConfig = {x: 15, y: 10, z: 15};
        this.controlConfig = {
            target: new THREE.Vector3(10, 5, 0),
            enableRotate: false,
            maxDistance: 15,
            minDistance: 5,
            enableZoom: true,
            enableRotation: false,
            minPolarAngle: 0,
            maxPolarAngle: Math.PI / 2
        }
        this.animations = [];
        this.model = null;
        this.preloadAssets(images);
    }

    init() {
        
    }

    destroy() {
 
    }

    /**
     *
     * @param name
     * @returns {Place}
     */
    getPlaceByName(name) {
        if (name === this.name) {
            return this;
        } 
    }

    /**
     *
     * @param model
     */
    matchModelWithPlace(model) {
    }

    setPlaceConfiguration() {
        sceneManager.setSceneConfig(this.cameraConfig, this.controlConfig);
        this.setLightConfiguration();
    }

    setLightConfiguration() {

    }

    addModelToPlace(model) {
        model.scale.set(0.05, 0.05, 0.05);
        model.position.set(0.0, 0.0, 0.0);
        this.model = model;
        this.group.add(model);
    }

    animator(animation) {
    }
    /**
     *
     * @param deltaTime
     */
    animate(deltaTime) {
    }

    addToSceneManager() {
        sceneManager.scene.add(this.group);
    }

    displayTextUI() {

    }

    removeSceneFromManager() {
        sceneManager.scene.remove(this.group);
    }

    setLastScene(lastScene) {
        this.lastScene = lastScene;
    }

    setOffset(offset) {
        this.offset = offset;
        sceneManager.onWindowResize();
    }

    convertToBasicMeshMaterial(model) {
        model.traverse(child => {
            if (child.isMesh) {
                const prevMaterial = child.material
                const newMaterial = new THREE.MeshBasicMaterial()
                newMaterial.copy(prevMaterial)
                newMaterial.map = prevMaterial.emissiveMap // Dans le cas ou il n'y a rien dans la propriété map
                child.material = newMaterial
            }
        });
    }

    convertToPhongMaterial(model, parameters = null) {
        model.traverse(child => {
            if (child.isMesh) {
                const prevMaterial = child.material
                const newMaterial = new THREE.MeshPhongMaterial(parameters)
                // newMaterial.copy(prevMaterial) 
                newMaterial.color = prevMaterial.color
                // newMaterial.map = prevMaterial.emissiveMap // Dans le cas ou il n'y a rien dans la propriété map
                child.material = newMaterial 
            }
        })
    }

    addModel(model, group, scale, position, rotation = null) {
        model.position.copy(position);
        if (rotation) {
            model.rotation.set(rotation.x, rotation.y, rotation.z);
        }
        model.scale.set(scale, scale, scale);
        group.add(model);
    }

    preloadAssets(images) {
        images.forEach((url) => (new Image()).src = `../svg/${url}.svg`);
    }
}
