import Place from '../classes/place';
import * as THREE from 'three';
import { sceneManager } from '../classes/sceneManager';
import uiManager from '../utils/uiManager';
import Json from '../helpers/json';
import ExperienceManager, { SECOND_SCENE } from '../utils/experienceManager';
import gsap from 'gsap/all'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { cursorSingleton } from '../uiComponents/cursor';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader';
import SoundManager, {WIN} from "../utils/soundManager";

export default class SceneTwo extends Place {
    constructor() {
        super(SECOND_SCENE,
            ['scene02_4.1.fbx', 'kamilleAnimationPart1_1.0.fbx', 'kamilleAnimationPart2_1.0.fbx', 'pascalAnimationPart1_1.0.fbx', 'pascalAnimationPart2_1.0.fbx', 'hubertAnimationTalking.fbx'],
            ['hubert_happy', 'kamille_angry', 'hubert_embarrassed', 'hubert_worried', 'pascal', 'kamille_happy']);
        this.setOffset(500);
        this.cameraConfig = { x: 42, y: 47, z: -42 };
        this.controlConfig = {
            ...this.controlConfig,
            target: new THREE.Vector3(0, 12, 0),
            maxDistance: 100,
            minDistance: 5,
            enableRotate: false,
            enableZoom: true
        };
        this.decors = null;
        this.brokenPipes = [];
        this.pipesModel = null;
        this.pnjs = new THREE.Group();
        this.modelIgnored = ['tuyau', 'hubert', 'paskal', 'kamille'];
        this.canMoveBrokenPipe = false;
        this.instruction = null;
        this.outlinePass = null;
        this.composer = null;
        this.selectedObjects = [];
        this.animations = [];
        this.mixer = [];

        this.floor = new THREE.Mesh(
            new THREE.PlaneGeometry(16, 20, 1, 1),
            new THREE.ShadowMaterial({
                opacity: 0.5,
                color: 0x000000,
            }),
            // new THREE.MeshBasicMaterial({
            //     opacity: 1,
            //     color: 0x00FF00,
            // })
        );
        this.initFloor()
    }

    displayTextUI() {
        super.displayTextUI();

        Json.getData('../data/secondScene.json', (data) => {
            uiManager.displayDialog(data['before-interaction'], () => {
                this.setOffset(0);
                this.focusOnPipes();
                uiManager.displayInteraction(data['first-interaction'], () => {
                    this.setOffset(500);
                    this.backToScene();
                    
                    this.addPnj(true);
                    uiManager.displayDialog(data['between-interaction'], () => {
                        this.setOutline();
                        this.canMoveBrokenPipe = true;
                        this.setOffset(0);
                        this.focusOnPipes();
                        cursorSingleton.registerCursor([], 'clique', this.onPipeHover);
                        uiManager.displayInteraction(data['second-interaction'], () => {
                            this.setOffset(500);
                            this.backToScene();
                            this.addPnj(true, true);
                            uiManager.displayDialog(data['after-interaction'], () => {
                                uiManager.displaySceneConclusion(data['scene-conclusion'], () => {
                                    ExperienceManager.backToTown();
                                });
                            }, SECOND_SCENE);
                        })
                    })
                })
            }, SECOND_SCENE, true);
        });
    }
    initFloor() {
        this.floor.geometry.rotateX(-Math.PI / 2)
        this.floor.position.set(-6, 1, -6)
        
        this.floor.receiveShadow = true;
        this.group.add(this.floor);
    }

    onPipeHover() {
        return !!sceneManager.mouseIntersect;
    }

    addToSceneManager() {
        this.addPnj();
        super.addToSceneManager();
    }

    addModelToPlace(model) {
        model.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });
        switch (model.name) {
            case 'scene02': {
                this.sceneModel = model;
                super.addModelToPlace(this.sceneModel);
                this.pipesModel = this.sceneModel.children.find((child) => child.name === 'pipeGroup');
                this.pipesModel.position.set(0, 0, 0);
                this.pipesModel.scale.set(0.05, 0.05, 0.05);
                // super.convertToBasicMeshMaterial(this.sceneModel)
                super.convertToPhongMaterial(this.pipesModel, { side: THREE.DoubleSide });
            } break;
            case 'kamilleAnimationPart1': {
                this.kamilleAnimationPart1 = model;
            } break;
            case 'kamilleAnimationPart2': {
                this.kamilleAnimationPart2 = model;
            } break;
            case 'pascalAnimationPart1': {
                this.pascalAnimationPart1 = model;
            } break;
            case 'pascalAnimationPart2': {
                this.pascalAnimationPart2 = model;
            } break;
            case 'hubertAnimationTalking': {
                this.hubertAnimationTalking = model;
            } break;
        }
    }

    addPnj(withPascal = false, part2 = false) {
        super.addModel(this.hubertAnimationTalking, this.group, 0.05, new THREE.Vector3(-10, 1, -10), new THREE.Vector3(0, Math.PI / 2, 0));
        this.animator(this.hubertAnimationTalking);

        if (part2) {
            super.addModel(this.kamilleAnimationPart2, this.group, 0.05, new THREE.Vector3(-4, 1, -14), new THREE.Vector3(0, - Math.PI / 4, 0));
            this.animator(this.kamilleAnimationPart2);
        } else {
            super.addModel(this.kamilleAnimationPart1, this.group, 0.05, new THREE.Vector3(-4, 1, -14), new THREE.Vector3(0, - Math.PI / 4, 0));
            this.animator(this.kamilleAnimationPart1);
        }

        if (withPascal) {
            if (part2) {
                super.addModel(this.pascalAnimationPart2, this.group, 0.05, new THREE.Vector3(-6, 1, -4), new THREE.Vector3(0, Math.PI, 0));
                this.animator(this.pascalAnimationPart2);
            } else {
                super.addModel(this.pascalAnimationPart1, this.group, 0.05, new THREE.Vector3(-6, 1, -4), new THREE.Vector3(0, Math.PI, 0));
                this.animator(this.pascalAnimationPart1);
            }

        }
    }

    removePnj(model) {
        this.mixer = [];
        this.group.remove(model);
    }

    setLightConfiguration() {
        super.setLightConfiguration();

        if (!this.hemiLight) {
            this.hemiLight = new THREE.HemisphereLight(0xffffff, 0xf1975d, .2);
            this.hemiLight.name = 'hemiLight';
            this.hemiLight.position.set(0, 70, 50);
            this.group.add(this.hemiLight);
            this.spotLight = new THREE.SpotLight(0xc4844a, 0.05);
            this.spotLight.position.set(14, 56, -35);

            this.spotLight.castShadow = true;
            this.spotLight.shadow.mapSize.width = 4096;
            this.spotLight.shadow.mapSize.height = 4096;
            this.spotLight.shadow.camera.near = 4;
            this.spotLight.shadow.camera.far = 2000;
            this.spotLight.shadow.camera.fov = 180;
            this.group.add(this.spotLight);
        }
    }

    animator(model) {
        let mixer = new THREE.AnimationMixer(model);
        let animation = THREE.AnimationClip.findByName(this.animations, model.name);
        const action = mixer.clipAction(animation);
        action.play();
        this.mixer.push(mixer);
    }

    findBrokenPipes() {
        sceneManager.elementsToIntersect = this.pipesModel.children;

        if (!this.brokenPipeInited) {
            this.brokenPipeInited = true;

            for (const miniCube of this.pipesModel.children) {
                let identifiers = miniCube.name.split("_");
                if ((identifiers[0] == "pipeBend" || identifiers[0] == "pipeBendJoint") && (identifiers[1] != "static")) {
                    miniCube.rotationAxis = identifiers[1];
                    // remove if
                    if (identifiers[2]) {
                        miniCube.rotationToFix = identifiers[2];
                        miniCube.rotationCount = 0;
                        this.brokenPipes.push(miniCube);
                        this.initBrokenPipe(miniCube);
                    }
                }
            }
        } else {
            this.clickOnPipe();
            this.updateSelectedObjects();
        }
    }

    focusOnPipes() {
        this.floor.receiveShadow = false;
        sceneManager.floor.receiveShadow = false;
        this.group.add(this.pipesModel);
        sceneManager.setSceneConfig(
            { x: 35, y: 0, z: 0 },
            { target: this.pipesModel.position, enableZoom: true, enableRotate: true, maxDistance: 15, minDistance: 5 },
        );
        this.removePnj(this.kamilleAnimationPart1);
        this.removePnj(this.pascalAnimationPart1);
        this.removePnj(this.hubertAnimationTalking);
        this.group.remove(this.sceneModel);

        this.findBrokenPipes();
    }

    backToScene() {
        this.floor.receiveShadow = true;
        sceneManager.floor.receiveShadow = true;
        this.group.add(this.sceneModel);
        sceneManager.elementsToIntersect = null;
        cursorSingleton.unregisterCursor();
        this.setPlaceConfiguration();
        this.group.remove(this.pipesModel);
    }

    clickOnPipe() {
        if (this.canMoveBrokenPipe) {
            this.onClick = () => {
                if (sceneManager.mouseIntersect) {
                    let intersectedPipe = sceneManager.mouseIntersect.object;
                    if (this.brokenPipes.includes(intersectedPipe)) {
                        this.rotatePipe(intersectedPipe);
                    }

                    this.checkInteractionFinished(this.brokenPipes);
                }
            }
            window.addEventListener('click', this.onClick);
        }
    }

    setOutline() {
        this.composer = new EffectComposer(sceneManager.renderer);
        this.composer.outputEncoding = THREE.sRGBEncoding;

        let renderPass = new RenderPass(sceneManager.scene, sceneManager.camera);
        renderPass.setSize(window.innerWidth, window.innerHeight);
        this.composer.addPass(renderPass);

        this.outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), sceneManager.scene, sceneManager.camera);
        this.outlinePass.depthMaterial.skinning = true;
        this.outlinePass.prepareMaskMaterial.skinning = true;
        this.outlinePass.visibleEdgeColor.set(0x002E4F);
        this.outlinePass.hiddenEdgeColor.set(0x3AA4F0);
        this.outlinePass.edgeGlow = 0;
        this.outlinePass.edgeThickness = 1; // Default is 1.
        this.outlinePass.edgeStrength = 3; // Default is 3.
        this.outlinePass.setSize(window.innerWidth, window.innerHeight);
        this.composer.addPass(this.outlinePass);
        const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
        this.composer.addPass(gammaCorrectionPass);
    }

    updateSelectedObjects(pipe = null) {
        if (pipe) {
            this.selectedObjects = this.selectedObjects.filter(item => item !== pipe)
        } else {
            this.selectedObjects = this.brokenPipes;
        }

        this.outlinePass.selectedObjects = this.selectedObjects;
    }


    initBrokenPipe(pipe) {
        let rotationToFix = parseInt(pipe.rotationToFix);
        pipe.canClick = true;
        switch (pipe.rotationAxis) {
            case 'x':
                gsap.set(pipe.rotation, {
                    x: pipe.rotation.x - (Math.PI / 2 * (rotationToFix)),
                });
                break;
            case 'y':
                gsap.set(pipe.rotation, {
                    y: pipe.rotation.y - (Math.PI / 2 * (rotationToFix)),
                });
                break;
            case 'z':
                gsap.set(pipe.rotation, {
                    z: pipe.rotation.z - (Math.PI / 2 * (rotationToFix))
                });
                break;
            default:
                break;
        }
    }

    rotatePipe(pipe) {
        if (!pipe.canClick) {
            return;
        }

        if (pipe.rotationCount < parseInt(pipe.rotationToFix)) {
            pipe.rotationCount++;

            pipe.canClick = false;
            switch (pipe.rotationAxis) {
                case 'x':
                    gsap.to(pipe.rotation, {
                        x: pipe.rotation.x + Math.PI / 2,
                        onComplete: () => pipe.canClick = true
                    });
                    break;
                case 'y':
                    gsap.to(pipe.rotation, {
                        y: pipe.rotation.y + Math.PI / 2,
                        onComplete: () => pipe.canClick = true
                    });
                    break;
                case 'z':
                    gsap.to(pipe.rotation, {
                        z: pipe.rotation.z + Math.PI / 2,
                        onComplete: () => pipe.canClick = true
                    });
                    break;
                default:
                    break;
            }

            if (pipe.rotationCount == parseInt(pipe.rotationToFix)) {
                this.updateSelectedObjects(pipe);
            }
        }
    }

    checkInteractionFinished(brokenPipe) {
        if (brokenPipe.every((pipe) => pipe.rotationCount == parseInt(pipe.rotationToFix))) {
            SoundManager.playSound(WIN);
            window.dispatchEvent(new Event('interaction_continue'));
            window.removeEventListener('click', this.onClick);
            this.composer = null;
        }
    }

    animate(deltaTime) {
        if (this.mixer.length) {
            this.mixer.forEach(mixer => {
                mixer.update(deltaTime);
            });
        }

        if (this.instruction) {
            const screenPosition = this.instruction.position.clone();
            screenPosition.project(sceneManager.camera);
            const translateX = screenPosition.x * sceneManager.sizes.width * 0.5;
            const translateY = - screenPosition.y * sceneManager.sizes.height * 0.5;
            this.instruction.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
        }
        if (this.composer) {
            this.composer.render();
        }
    }
}
