import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader';
import { sceneManager } from '../classes/sceneManager';

export default class AssetLoader {
    constructor() {
        this.initElements();
        this.initEvents();
        sceneManager.assetLoader = this;
    }

    initElements() {
        this.loadingScreen = document.querySelector('.Loader');
        this.progressBar = document.getElementById('progressBar');
        this.progressNumber = document.getElementById('progressNumber');
        this.models = [];
        this.gltfLoader = null;
        this.models = [];
    }

    initEvents() {
        this.initLoading();
    }

    initLoading() {
        const loadingManager = new THREE.LoadingManager(
            // Loaded
            () => {
                this.loadingScreen.classList.add('ended');
            },

            // Progress
            (itemUrl, itemsLoaded, itemsTotal) => {
                // Calculate the progress and update the progressBar
                const progressRatio = itemsLoaded / itemsTotal;
                this.progressBar.style.height = `${progressRatio * 100}%`;
                this.progressNumber.innerHTML = (progressRatio * 100).toFixed(0) + "%";
            }
        )
        this.gltfLoader = new GLTFLoader(loadingManager);
        this.fbxLoader = new FBXLoader(loadingManager);
    }

    loadModel(place, onLoad = null) {
        this.loadingScreen.classList.remove('ended');
        this.progressBar.style.height = '0%';
        this.progressNumber.innerHTML = '0%';

        let counter = 1;
        place.files.forEach((file) => {
            if (file.match(/\.fbx/)) {
                this.fbxLoader.load(
                    '../models/' + file,
                    (object) => {
                        object.name = file.split("_")[0].replace(/\.fbx/, '');
                        object.traverse(function (child) {
                            if (child.isMesh) {
                                child.castShadow = true;
                                child.receiveShadow = true;
                            }
                        });

                        
                        if (object.animations) {
                            for (const animation of object.animations) {
                                animation.name = object.name;
                                place.animations.push(animation);
                            }
                        }

                        this.models.push(object);
                        place.addModelToPlace(object);
                        if (onLoad && counter === place.files.length) {
                            onLoad();
                        }
                        counter++;
                    }
                )
            } else {
                this.gltfLoader.load(
                    '../models/' + file,
                    (gltf) => {
                        gltf.scene.name = file.split("_")[0].replace(/\.(gltf|glb)/, '');
                        const model = gltf.scene;
                        if (gltf.animations) {
                          for (const animation of gltf.animations) {
                              place.animations.push(animation)
                          }
                        }
                        this.models.push(model);
                        place.addModelToPlace(model);
                        if (onLoad && counter === place.files.length) {
                            onLoad();
                        }
                        counter++;
                    }
                )
            }
        });
    }

    matchModelWithPlace() {
        this.models.forEach(model => {
            sceneManager.places[model.name].addModelToPlace(model);
        });
    }
}

